import React, { useState } from "react";
import { MdSearch } from "react-icons/md";
import { BiLeftArrowAlt } from "react-icons/bi"
import { NavLink, useNavigate } from "react-router-dom";
import { LocationModal } from "../PopupModal";
const MobileNavBar = ({ HandleNavigate }) => {
    const Navigate = useNavigate()
    const [LocationpopupType, setLocationpopupType] = useState("")
    const [LocationAddress, setLocationAddress] = useState("")
    const HandleNavigat = () => {
        Navigate("/")
    }
    const HandlepopupAddress = () => {
        console.log(LocationpopupType)
        if (LocationpopupType === "success") {
            setLocationpopupType("")
            setTimeout(() => {
                setLocationpopupType("success")
            }, 100)
        }
        else {
            setLocationpopupType("success")
        }
    }
    function formatOpeningTime(openingTime) {
        // Assuming openingTime is in 24-hour format HH:mm
        const [hours, minutes] = openingTime.split(':');
        const formattedHours = parseInt(hours) % 12 || 12;
        const amOrPm = parseInt(hours) >= 12 ? 'PM' : 'AM';
        return `${formattedHours}:${minutes} ${amOrPm}`;
      }
    return (
        <>
            <div className="navBar-mobile-main">
                <header className="nav-mobile-header">
                    <div className="nav-mobile-location1">
                        <div onClick={HandleNavigate ? () => HandleNavigate() : HandleNavigat}>
                            <BiLeftArrowAlt className="leftarroworder" />
                        </div>
                       <div className="navmbf2">
                            <div className="nav-mobile-time">
                               {localStorage.getItem("site_data").ecommerce_type === "Hyperlocal" ? (localStorage.getItem("express_delivery_time") ? `Delivery in ${localStorage.getItem("express_delivery_time")} minutes` : "Delivering to") : "Delivering to"}
                            </div>
                           {localStorage.getItem("site_data").ecommerce_type === "Hyperlocal"&&<div className="nav-mobile-store">
                                {localStorage.getItem("address")}
                            </div>}
                        </div>
                    </div>
                    <div className="search-mobile-main">
                        <NavLink to="/s" className="position-relative">
                            <div className="form-control">
                                <MdSearch className="search-icon" />
                                <input type="text" />
                            </div>
                        </NavLink>
                    </div>
                </header>
            </div>
            {
                LocationpopupType === "success" &&
                <LocationModal LocationpopupType={LocationpopupType} setLocationpopupType={setLocationpopupType} setLocationAddress={setLocationAddress} />
            }
        </>
    )
}
export { MobileNavBar }