import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import axiosconfig from "../../Service/axiosConfig"

const fetchUpdteProducts=async(id)=>{
     const Responce=await axiosconfig.get(`/catlog/products/${id}/`)
     return Responce.data;
}

const getLocaData = async () => {
  const newData = localStorage.getItem("state");
  let cartData = JSON.parse(newData);
  let total = 0;
  if (newData === null) {
    return {
      products: [],
      total: 0,
    };
  } else {
    if (cartData.length > 0) {
      for (let i = 0; i < cartData.length; i++) {
        const res = cartData[i];
        const updatedProduct = await fetchUpdteProducts(res.id);
        if (updatedProduct) {
          // Check if offer_price or price has changed
          if (
            res.offer_price !== updatedProduct.offer_price ||
            res.price !== updatedProduct.price
          ) {
            let updatedCartItem = {
              ...res,
              price: updatedProduct.price,
              offer_price: updatedProduct.offer_price,
            };
            cartData[i] = updatedCartItem;
          }
          const basePrice = updatedProduct.offer_price > 0 ? updatedProduct.offer_price : updatedProduct.price;
          total += res.quantity * basePrice;
        }
      }
    }
    console.log(total)
    return {
      products: cartData,
      total: total,
    };
  }
};

const getLocaTotal = (state) => {
  const NewData = localStorage.getItem("total")
  if (NewData === null) {
    return 0
  }
  else {
    console.log(NewData)
    return parseFloat(NewData)

  }
}
const getLocalWishlists = (state) => {
  const NewData = localStorage.getItem("wishlists")
  if (NewData === null) {
    return []
  }
  else {
    return JSON.parse(NewData)
  }
}
const initialState = {
  products:[],
  wishlists: getLocalWishlists(),
  total:0,
};
export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    addProduct: (state, action) => {
      const { product, quantity } = action.payload;
      const existingProduct = state.products.find((p) => p.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += quantity
      } else {
        state.products.push({ ...product, quantity });
      }
      console.log(product, state)
      const basePrice = product.offer_price > 0 ? product.offer_price : product.price;
      const totalPrice = parseFloat(basePrice) + (isNaN(product.variant_Price) ? 0 : product.variant_Price);
      state.total += quantity * totalPrice;
      console.log(state.total, quantity, parseFloat(basePrice),product.offer_price, totalPrice, "total")
      localStorage.setItem("total", state.total)
      localStorage.setItem("state", JSON.stringify(state.products))
    },
    addWishlist: (state, action) => {
  const { productId, wish_id } = action.payload;

  const existingProduct = state.wishlists.find((p) => p.id === productId);
  if (!existingProduct) {
    const newProduct = { id: productId, wish_id: wish_id };
    state.wishlists.push(newProduct);
    localStorage.setItem("wishlists", JSON.stringify(state.wishlists));
  }
},

    removeWishlist: (state, action) => {
      const { productId } = action.payload;
    
      const productIndexToRemove = state.wishlists.findIndex(
        (p) => p.id === productId
      );
    
      if (productIndexToRemove !== -1) {
        state.wishlists.splice(productIndexToRemove, 1);
        localStorage.setItem("wishlists", JSON.stringify(state.wishlists));
      }
    },
    removeProduct: (state, action) => {
      const { productId, quantity } = action.payload;
      const existingProduct = state.products.find((p) => p.id === productId);

      if (existingProduct) {
        if (existingProduct.quantity > quantity) {
          existingProduct.quantity -= quantity;
          const basePrice = existingProduct.offer_price > 0 ? existingProduct.offer_price : existingProduct.price;
          const totalPrice = parseFloat(basePrice) + (isNaN(existingProduct.variant_Price) ? 0 : existingProduct.variant_Price);
          state.total -= quantity * totalPrice;
          localStorage.setItem("total", state.total)
          localStorage.setItem("state", JSON.stringify(state.products))
        } else {
          state.products = state.products.filter((p) => p.id !== productId);
          const basePrice = existingProduct.offer_price > 0 ? existingProduct.offer_price : existingProduct.price;
          const totalPrice = parseFloat(basePrice) + (isNaN(existingProduct.variant_Price) ? 0 : existingProduct.variant_Price);
          state.total -= quantity * totalPrice;
          localStorage.setItem("total", state.total)
          localStorage.setItem("state", JSON.stringify(state.products))
        }
      }
    },
    clearCart: (state) => {
      state.products = [];
      state.total = 0;
      state.wishlists = [];
    },
  },

});
export const selectCartProducts = state => state.cart.products
export const selectWishlists = state => state.cart.Wishlists
export const selectCartTotal = state => state.cart.total

export const { addProduct, removeProduct, clearCart, addWishlist, removeWishlist,setProducts,setTotal } = cartSlice.actions;
export const fetchProducts = () => async (dispatch) => {
  const { products, total } = await getLocaData();
  dispatch(setProducts(products));
  dispatch(setTotal(total));
};
export default cartSlice.reducer;