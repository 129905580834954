import React, { useEffect, useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import axiosConfig from "../../Service/axiosConfig"
import { AccessDeny } from "./AccesssDeny";
import { HiOutlineLocationMarker } from "react-icons/hi"
const LocationModal = ({ LocationpopupType, setLocationpopupType, setLocationAddress, Location, SetOnline }) => {
  const [show, setShow] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);
  const inputRef = useRef();
  const [NoAccess, setNoAccess] = useState("");
  const [storeDetails, setStoreDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const HandleAccess = () => {
    setShow(false)
    setNoAccess("success")
  }
  const handlePlaceChanged = async () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      console.log(place.formatted_address)
      console.log(place.geometry.location.lat())
      console.log(place.geometry.location.lng())
      try {
        const response = await axiosConfig.get(`/stores/get_store/?latitude=${place.geometry.location.lat()}&longitude=${place.geometry.location.lng()}`);

        if (response.data.Message.store_id !== null) {
          localStorage.setItem("storeId", response.data.Message.store_id);
          localStorage.setItem("address", place.formatted_address);
          localStorage.setItem("address_id", "")
          localStorage.setItem("traveltime", response.data.Message.travel_time);
          localStorage.setItem("latitude", place.geometry.location.lat());
          localStorage.setItem("longitude", place.geometry.location.lng());
          localStorage.setItem("opens_at", response.data.Message.opens_at);
          localStorage.setItem("closes_at", response.data.Message.closes_at);
          localStorage.setItem("slot_duration", response.data.Message.slot_duration);
          localStorage.setItem("schedule_delivery", response.data.Message.schedule_delivery)
          localStorage.setItem("express_delivery", response.data.Message.express_delivery)
          if (response.data.Message.express_delivery && response.data.Message.express_delivery_time) {
            localStorage.setItem("express_delivery_time", response.data.Message.express_delivery_time)
          }
          setShow(false);
          setLocationAddress(place.formatted_address);
          setLocationpopupType && setLocationpopupType("");
          console.log("kkjjk");
          window.location.reload(); // Reload the window after successful API call
        } else {
          HandleAccess();
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  const handleError = (message) => {
    setError(message);
    console.error(message);
  };
    const fetchStoreData = async (latitude, longitude) => {
    try {
      setLoading(true); 
      const response = await axiosConfig.get(`/stores/get_store/?latitude=${latitude}&longitude=${longitude}`);
      console.log('Store Data Response:', response);

      if (response.data.Message.store_id && response.data.Message.is_online === "online") {
        setStoreDetails(response.data.Message);
        localStorage.setItem("storeId", response.data.Message.store_id);
        localStorage.setItem("opens_at", response.data.Message.opens_at);
        localStorage.setItem("closes_at", response.data.Message.closes_at);
        localStorage.setItem("slot_duration", response.data.Message.slot_duration);
        localStorage.setItem("traveltime", response.data.Message.travel_time);

        await fetchAndSaveAddress(latitude, longitude);
      } else {
        HandleAccess();
      }
    } catch (error) {
      handleError('Failed to fetch store data.');
    } finally {
      setLoading(false); 
    }
  };
    const fetchAndSaveAddress = async (latitude, longitude) => {
    try {
      const geocodingResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ`
      );

      if (geocodingResponse.ok) {
        const geocodingData = await geocodingResponse.json();
        if (geocodingData.results && geocodingData.results.length > 0) {
          const address = geocodingData.results[0].formatted_address;
          localStorage.setItem("address", address);
          localStorage.setItem("latitude", latitude);
          localStorage.setItem("longitude", longitude);
          setLocationAddress(address);
        } else {
          setDefaultLocationData();
        }
      } else {
        setDefaultLocationData();
      }
    } catch (error) {
      handleError('Failed to fetch address data.');
      setDefaultLocationData();
    }
  };
    const setDefaultLocationData = () => {
    localStorage.setItem("address", "");
    localStorage.setItem("latitude", "");
    localStorage.setItem("longitude", "");
    setLocationAddress('');
  };
 const handleGeolocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    setLatitude(latitude);
    setLongitude(longitude);
    fetchStoreData(latitude, longitude);
  };
  const handleGeolocationError = (error) => {
    handleError('Geolocation error: ' + error.message);
  };
  const handleLocationDetection = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        handleGeolocationSuccess,
        handleGeolocationError,
        setShow(false)
      );
    } else {
      handleError('Geolocation is not supported by your browser');
    }
  };
const handleClose = () => {
    if (localStorage.getItem("storeId")) {
      setShow(false)
      setLocationpopupType("")
      console.log("ffgg")
    }
    else {
      setLocationpopupType("success")
      setShow(true)
    }
  };
  const handleShow = () => setShow(true);
  const GetData = () => {
    if (LocationpopupType === "success") {
      setShow(true)
      console.log("sss")
    }
    else {
      setShow(false)
      setLocationpopupType && setLocationpopupType("")
    }
  }
  useEffect(() => {
    GetData()
  }, [LocationpopupType])
  return (
    <>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton={localStorage.getItem("storeId") ? true : false}>
            <Modal.Title className="change-location-title">{localStorage.getItem("storeId") ? "Change Location" : "Welcome to opoto"}</Modal.Title>
            {/* <Modal.Title className="change-location-title">{localStorage.getItem("storeId") ? "Change Location" : "Welcome"}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {localStorage.getItem("storeId") ?
              <div className="d-flex align-items-center justify-content-center">
                <button type="button" className="btn--link" onClick={handleLocationDetection}>Detect My Location</button>
                <div className="oval-container">
                  <div className="oval">
                    <span className="separator-text">
                      <div className="or">
                        OR
                      </div>
                    </span>
                  </div>
                </div>
                <div>
                  <LoadScript googleMapsApiKey="AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ" libraries={["places"]}>
                    <StandaloneSearchBox
                      onLoad={ref => inputRef.current = ref}
                      onPlacesChanged={handlePlaceChanged}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Location"

                      />
                    </StandaloneSearchBox>
                  </LoadScript>
                </div>
              </div>
              :
              <div className="d-flex gap-2">
                <div className="desktop">
                  <HiOutlineLocationMarker className="location-modal" />
                </div>
                <div className="delvery--para">
                  <p className="d-flex gap-2">
                    <div className="mobile">
                      <HiOutlineLocationMarker className="location-modal" />
                    </div>
                    Please Provide your delivery location to see <br className="desktop"/>products at nearby share
                  </p>
                  <div className="d-flex align-items-center">
                    <button type="button" className="btn--link" onClick={handleLocationDetection}>Detect My Location</button>
                    <div className="oval-container">
                      <div className="oval">
                        <span className="separator-text">
                          <div className="or">
                            OR
                          </div>
                        </span>
                      </div>
                    </div>
                    <div>
                      <LoadScript googleMapsApiKey="AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ" libraries={["places"]}>
                        <StandaloneSearchBox
                          onLoad={ref => inputRef.current = ref}
                          onPlacesChanged={handlePlaceChanged}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Location"

                          />
                        </StandaloneSearchBox>
                      </LoadScript>
                    </div>
                  </div>
                </div>
              </div>
            }
          </Modal.Body>
        </Modal>
        {

          NoAccess === "success" &&
          <AccessDeny NoAccess={NoAccess} setNoAccess={setNoAccess} setLocationAddress={setLocationAddress} />
        }
      </div>
    </>
  )
}
export { LocationModal }