import React, { useState, useEffect } from "react";
import "./Navbar.css";
import axiosConfig from "../../Service/axiosConfig";
import { useSelector } from "react-redux";
import {
  selectCartTotal,
  selectCartProducts,
} from "../../redux/Slices/cartSlice";
import {
  MdShoppingCart,
  MdOutlineHome,
  MdSearch,
  MdOutlineAddCircleOutline,
  MdWorkOutline,
  MdOtherHouses,
  MdArrowDropDown,
} from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { ViewCart } from "./ViewCart";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { Login } from "../Login";
import { AddressModal, LocationModalNavBar, PopupModal } from "../PopupModal";
import { IoMdArrowDropdown } from "react-icons/io";
import { AccessDeny, LocationModal } from "../PopupModal";
import { error } from "jquery";
import { RiAccountCircleLine, RiBook3Line } from "react-icons/ri";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { AiOutlineHeart } from "react-icons/ai";
import { IoIosHeartEmpty } from "react-icons/io";
import { Wishlistpage } from "../../Pages/Wishlistpage";
import { LoginModal } from "../LoginModal/LoginModal";
import { FormParser } from "../FormParser/FormParser";
import Masters from "../../Components/masters.json";
import ViewCartModal from "./ViewCartModal";
import AddAddress from "../PopupModal/AddAddress";
import { HomeMeta } from "../../Components";
import { CiDiscount1, CiShop, CiMobile3 } from "react-icons/ci";
import { PiBookLight } from "react-icons/pi";

const Navbar = ({ search, LoginAccount }) => {
  const Navigate = useNavigate();
  const [name, setName] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [amount, setAmount] = useState("");
  const { cart } = useSelector((state) => state);
  const total = useSelector(selectCartTotal);
  const [show, setShow] = useState(false);
  const [LoggedIn, setLoggedIn] = useState(false);
  const [loginShow, setloginShow] = useState(false);
  const [query, setQuery] = useState(search);
  const [count, setCount] = useState(1);
  const [showWishlist, setShowWishlist] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [popupType, setPopupType] = useState("");
  const [addressModal, setAddressModal] = useState(false);
  const [Address, setAddress] = useState([]);
  const [news, SetNews] = useState(null);
  const [defaultAddress, setDefaultAddress] = useState();
  const [orderAddress, setOrderAddress] = useState();
  const [ActiveUrl, setActiveUrl] = useState("");
  const [LocationAddress, setLocationAddress] = useState("");
  const [LocationNavBarType, setLocatioNavBarType] = useState("");
  const [NoAccess, setNoAccess] = useState("");
  const [LocationpopupType, setLocationpopupType] = useState("");
  const [NoStock, setNoStock] = useState([]);
  const [Error, SetError] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const products = useSelector(selectCartProducts);
  const [errorMsg, setErrorMsg] = useState(null);
  const [Totalamount, setTotalAmount] = useState({
    Total_amount: 0,
  });
  const [siteData, setSiteData] = useState({});
  const [message, setMessage] = useState(null);
  const MastersModal =
    JSON.parse(localStorage.getItem("site_data"))?.ecommerce_type ===
    "Hyperlocal"
      ? Masters.AddAddressHyperlocal
      : Masters.AddAddress;
  const [ecommerceType, setEcommerceType] = useState("");
  const [storeNull, setStoreNull] = useState(false);
  const [Online, SetOnline] = useState(false);
  const [OpensAt, SetOpensAt] = useState(false);
  const [travelTime, SetTravelTime] = useState();
  const handleResize = () => {
    setIsMobile(window.innerWidth < 1020);
  };
  let ProductData = [];
  const initialOrderState = {
    address: "",
    products: [],
    stores: localStorage.getItem("stores"),
    user_id: localStorage.getItem("userId"),
    packing_charges: 0,
    delivery_charges: 0,
  };
  const scheduleDate = localStorage
    .getItem("selected_date")
    ?.split("-")
    .reverse()
    .join("-");
  if (scheduleDate) {
    initialOrderState.schedule_date = scheduleDate;
  }
  const [Order, setOrders] = useState(initialOrderState);
  console.log(Order, cart);
  function handleInputChange(event) {
    setQuery(event.target.value);
  }
  const ActiveData = (activeUrl) => {
    localStorage.setItem("activeurl", activeUrl);
  };
  const GetlocaData = () => {
    const Accounturl = localStorage.getItem("activeurl");
    setActiveUrl(Accounturl);
  };
  function handleSubmit(event) {
    event.preventDefault();
    Navigate(`/product-search?query=${query}`);
  }
  const handleLoginShow = () => {
    setloginShow(true);
  };
  const GetData = async () => {
    try {
      const response = await axiosConfig.get(
        `/accounts/address/?user=${localStorage.getItem(
          "userId"
        )}&is_suspended=false`
      );
      setAddress(response.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const GetNews = async () => {
    try {
      const news_response = await axiosConfig.get(`/catlog/Scrolling_News/`);
      const firstDate = new Date(news_response.data.results[0].scrolling_upto);
      const today = new Date();
      const firstDateMidnight = new Date(firstDate.setHours(0, 0, 0, 0));
      const todayMidnight = new Date(today.setHours(0, 0, 0, 0));
      if (firstDateMidnight.getTime() > todayMidnight.getTime()) {
        SetNews({ ...news_response.data.results[0], show_news: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetSiteData = async () => {
    const site_response = await axiosConfig.get("/sitedata/site/");
    console.log("site data", site_response.data.results);
    if (site_response.data.results.length > 0) {
      setSiteData(site_response.data.results[0]);
      // document.title = site_response.data.results[0].title;
      const favicon =
        document.querySelector("link[rel='icon']") ||
        document.createElement("link");
      favicon.rel = "icon";
      favicon.href = site_response.data.results[0].favicon.image;
      // document.head.appendChild(favicon);
      setEcommerceType(site_response.data.results[0].ecommerce_type);
      localStorage.setItem(
        "site_data",
        JSON.stringify(site_response.data.results[0])
      );
      const root = document.documentElement;
      const primary_color = site_response.data.results[0].default_primary
        ? "#3cac47"
        : site_response.data.results[0].primary_color;
      const secondary_color = site_response.data.results[0].default_secondary
        ? "#e6ffe8"
        : site_response.data.results[0].secondary_color + "80";
      root.style.setProperty("--primary_color", primary_color);
      root.style.setProperty("--secondary_color", secondary_color);
      if (site_response.data.results[0].ecommerce_type === "Hyperlocal") {
        HandleLocationPopup();
      } else {
        const default_response = await axiosConfig.get(
          `/stores/stores/?is_default=true`
        );
        if (
          default_response.data.results.length > 0 &&
          localStorage.getItem("storeId") === null
        ) {
          setDefaultAddress(default_response.data.results[0].address);
          localStorage.setItem("storeId", default_response.data.results[0].id);
          if (!JSON.parse(localStorage.getItem("address"))) {
            localStorage.setItem(
              "address",
              default_response.data.results[0].address
            );
          }
          localStorage.setItem(
            "latitude",
            default_response.data.results[0].latitude
          );
          localStorage.setItem(
            "longitude",
            default_response.data.results[0].longitude
          );
        }
      }
    }
  };
  const GetTheAddress = () => {
    setCount(2);
    GetData();
  };
  const GetAddress = async (id) => {
    try {
      if (
        JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
        "Conventional"
      ) {
        return null;
      }
      const response = await axiosConfig.get(`/accounts/address/${id}/`);
      const store_response = await axiosConfig.get(
        `/stores/get_store/?latitude=${response.data.latitude}&longitude=${response.data.longitude}`
      );
      if (
        store_response.data.Message.store_id !== null &&
        ecommerceType === "Hyperlocal"
      ) {
        localStorage.setItem(
          "online",
          store_response.data.Message.is_online === "offline" ? true : false
        );
        localStorage.setItem("storeId", store_response.data.Message.store_id);
        localStorage.setItem("opens_at", store_response.data.Message.opens_at);
        localStorage.setItem(
          "closes_at",
          store_response.data.Message.closes_at
        );
        localStorage.setItem(
          "slot_duration",
          store_response.data.Message.slot_duration
        );
        // window.location.reload();
        setOrderAddress(response.data);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("address_id", response.data.id);
        localStorage.setItem("address", response.data.address);
        localStorage.setItem("latitude", response.data.latitude);
        localStorage.setItem("longitude", response.data.longitude);
        if (store_response.data.Message.is_online === "offline") {
          localStorage.setItem("express_delivery", false);
          localStorage.removeItem("express_delivery_time");
          SetTravelTime();
        } else {
          localStorage.setItem(
            "express_delivery",
            store_response.data.Message.express_delivery
          );
          if (
            store_response.data.Message.express_delivery &&
            store_response.data.Message.express_delivery_time
          ) {
            localStorage.setItem(
              "express_delivery_time",
              store_response.data.Message.express_delivery_time
            );
            SetTravelTime(store_response.data.Message.express_delivery_time);
          } else {
            localStorage.removeItem("express_delivery_time");
            SetTravelTime();
          }
        }
        cart.products.forEach((res) => {
          console.log(res);
          ProductData.push({
            id: res.id,
            min_qty: res.quantity,
          });
        });
        setOrders({
          ...Order,
          stores: localStorage.getItem("storeId"),
          user_id: parseFloat(localStorage.getItem("userId")),
          address: id,
          products: ProductData,
        });
        setStoreNull(false);
      } else if (ecommerceType === "Conventional") {
        setOrderAddress(response.data);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("address_id", response.data.id);
        localStorage.setItem("address", response.data.address);
        localStorage.setItem("latitude", response.data.latitude);
        localStorage.setItem("longitude", response.data.longitude);
        cart.products.forEach((res) => {
          console.log(res);
          ProductData.push({
            id: res.id,
            min_qty: res.quantity,
          });
        });
        setOrders({
          ...Order,
          stores: localStorage.getItem("storeId"),
          user_id: parseFloat(localStorage.getItem("userId")),
          address: id,
          products: ProductData,
        });
      } else {
        setStoreNull(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const ChangeAddress = () => {
    setCount(2);
    setOrderAddress();
  };
  const MovetoCart = () => {
    setCount(1);
    setOrderAddress();
    setStoreNull(false);
  };
  const HandleAddress = () => {
    setAddressModal(true);
  };

  const closeAddressModal = () => {
    setAddressModal(false);
    setErrorMsg(null);
    SetError("");
  };

  const formSubmit = async (Data) => {
    try {
      axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
      const store_response = await axiosConfig.get(
        `/stores/get_store/?latitude=${Data.latitude}&longitude=${Data.longitude}`
      );
      if (store_response.data.Message.store_id !== null) {
        const res = await axiosConfig.post(`/accounts/address/`, {
          ...Data,
          user: localStorage.getItem("userId"),
        });
        console.log(res, "address added");
        setPopupType("success");
        setAddressModal(false);
        setMessage("Address added successfully");
        GetData();
      } else if (
        store_response.data.Message.store_id === null &&
        ecommerceType === "Conventional"
      ) {
        const res = await axiosConfig.post(`/accounts/address/`, {
          ...Data,
          user: localStorage.getItem("userId"),
        });
        console.log(res, "address added");
        setPopupType("success");
        setAddressModal(false);
        setMessage("Address added successfully");
        GetData();
      } else {
        setPopupType("");
        SetError("No service Available at this location");
        setMessage("No service Available at this location");
      }
    } catch (error) {
      console.error(error);
      SetError("error adding address");
      setPopupType("");
    }
  };

  const PayMentNavigate = async () => {
    let order_obj = Order;
    let request_type = "post";
    let url = "/accounts/orders/";
    if (localStorage.getItem("orderId")) {
      order_obj["order_id"] = localStorage.getItem("orderId");
      request_type = "put";
      url = `/accounts/orders/${localStorage.getItem("orderId")}/`;
    }
    try {
      axiosConfig.defaults.headers["Content-Type"] = "application/json";
      const res = await axiosConfig[request_type](url, Order);
      localStorage.setItem("orderId", res.data.id);
      Navigate("/checkout");
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data.message) {
        setSmShow(true);
        setErrorMsg(error.response.data.message);
      }
      if (
        error.response &&
        error.response.data[
          "The following products are not available in the store:"
        ]
      ) {
        error.response.data[
          "The following products are not available in the store:"
        ].forEach(async (res) => {
          try {
            const productRes = await axiosConfig.get(
              `/catlog/products/${res.id}/?is_suspended=false`
            );
            setNoStock((prestate) => [...prestate, productRes.data]);
            setSmShow(true);
          } catch (error) {
            console.error(error);
          }
        });
      }
    }
  };
  const AddressLocal = async (id) => {
    try {
      const response = await axiosConfig.get(
        `/accounts/address/${localStorage.getItem("address_id")}/`
      );
      setOrderAddress(response.data);
      cart.products.forEach((res) => {
        console.log(res);
        ProductData.push({
          id: res.id,
          min_qty: res.quantity,
        });
      });
      setOrders({
        ...Order,
        stores: localStorage.getItem("storeId"),
        user_id: parseFloat(localStorage.getItem("userId")),
        address: localStorage.getItem("address_id"),
        products: ProductData,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const HndleSmallShow = () => {
    setSmShow(false);
    setNoStock("");
    setErrorMsg(null);
  };
  const checkLogin = () => {
    setLoggedIn(true);
  };
  const HandleLocationPopup = () => {
    if (localStorage.getItem("storeId")) {
      setLocationpopupType("");
    } else {
      setLocationpopupType("success");
    }
  };
  const HandlepopupAddress = () => {
    console.log(LocationpopupType);
    if (LocationpopupType === "success") {
      setLocationpopupType("");
      setTimeout(() => {
        setLocationpopupType("success");
      }, 100);
    } else {
      setLocationpopupType("success");
    }
  };
  const handlePlaceChanged = async (latitude, longitude) => {
    try {
      const response = await axiosConfig.get(
        `/stores/get_store/?latitude=${latitude}&longitude=${longitude}`
      );

      if (response.data.Message.store_id !== null) {
        console.log(response.data.Message);
        localStorage.setItem(
          "online",
          response.data.Message.is_online === "offline" ? true : false
        );
        localStorage.setItem("opens_at", response.data.Message.opens_at);
        localStorage.setItem(
          "schedule_delivery",
          response.data.Message.schedule_delivery
        );
        if (response.data.Message.is_online === "offline") {
          localStorage.setItem("express_delivery", false);
          localStorage.removeItem("express_delivery_time");
        } else {
          localStorage.setItem(
            "express_delivery",
            response.data.Message.express_delivery
          );
          if (
            response.data.Message.express_delivery &&
            response.data.Message.express_delivery_time
          ) {
            localStorage.setItem(
              "express_delivery_time",
              response.data.Message.express_delivery_time
            );
            SetTravelTime(response.data.Message.express_delivery_time);
          } else {
            localStorage.removeItem("express_delivery_time");
            SetTravelTime();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("address_id")) {
      AddressLocal();
    }
    if (localStorage.getItem("token") || LoginAccount) {
      checkLogin();
    }
    const latitude = localStorage.getItem("latitude");
    const longitude = localStorage.getItem("longitude");
    if (latitude && longitude) {
      handlePlaceChanged(latitude, longitude);
    }
    GetData();
    GetNews();
    GetSiteData();
    GetlocaData();
    // HandleLocationPopup();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [LoginAccount]);
  const MobileLogin = () => {
    Navigate("/account");
  };
  const handlePrivacyClick = (event) => {
    event.preventDefault();
    Navigate("/privacy");
  };
  const openWishlistPopup = () => {
    if (showWishlist) {
      Navigate("/wishlist");
    } else {
      if (LoggedIn) {
        setShowWishlist(true);
        Navigate("/wishlist");
      } else {
        setloginShow(true);
      }
    }
  };
  function formatOpeningTime(openingTime) {
    // Assuming openingTime is in 24-hour format HH:mm
    const [hours, minutes] = openingTime.split(":");
    const formattedHours = parseInt(hours) % 12 || 12;
    const amOrPm = parseInt(hours) >= 12 ? "PM" : "AM";
    return `${formattedHours}:${minutes} ${amOrPm}`;
  }
  return (
    <>
      {isMobile ? (
        <div className="navBar-mobile-main">
          <header className="nav-mobile-header">
            <div className="nav-mobile-location">
              <div
                onClick={
                  ecommerceType === "Hyperlocal" ? HandlepopupAddress : null
                }
              >
                <div className="nav-mobile-time">
                  {ecommerceType === "Hyperlocal"
                    ? travelTime
                      ? `Delivery in ${travelTime} minutes`
                      : "Delivering to"
                    : "Delivering to"}
                </div>

                {ecommerceType === "Hyperlocal" ? (
                  <div className="nav-mobile-store">
                    {localStorage.getItem("address")}
                    <MdArrowDropDown size={24} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div onClick={LoggedIn ? MobileLogin : handleLoginShow}>
                <RiAccountCircleLine className="account-mobile" />
              </div>
            </div>
            <div className="search-mobile-main">
              <NavLink to="/s" className="position-relative">
                <div className="search-div">
                  <MdSearch className="search-icon" />

                  <input
                    type="text"
                    className="form-control"
                    value={query}
                    onChange={handleInputChange}
                  />
                </div>
              </NavLink>
            </div>
          </header>
        </div>
      ) : (
        <>
          <div className="grocery-essential">
            <div className="container d-flex justify-content-between">
              <div className="grocery-essential-para">
                {news?.show_news && (
                  <marquee direction="right" scrollamount="5">
                    {news?.scrolling_point}
                  </marquee>
                )}
              </div>
              <ul className="grocery-essential-ul">
                <li className="grocery-essential-li">
                  <CiDiscount1 className="icon" />
                  <NavLink to="/offers" className="grocery-essential-data">
                    OFFERS
                  </NavLink>
                </li>
                <li className="grocery-essential-li">
                  <PiBookLight className="icon" />
                  <NavLink to="/blogs" className="grocery-essential-data">
                    BLOGS
                  </NavLink>
                </li>
                <li className="grocery-essential-li">
                  <CiMobile3 className="icon" />
                  <a
                    href="https://play.google.com/store/search?q=opoto&c=apps&hl=en"
                    target="_blank"
                    className="grocery-essential-data"
                  >
                    GET APP
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="navbar-container">
            <nav className="navbar fixed-top navbar-expand-lg">
              <div className="container-fluid">
                {console.log("site data", siteData)}
                <NavLink to="/" className="navbar-brand">
                  {siteData.logo ? (
                    <img src={siteData.logo.image} alt="logo" />
                  ) : (
                    <p>logo</p>
                  )}
                </NavLink>
                <div className="v-divider"></div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <div
                    className="location-nav-main"
                    onClick={
                      ecommerceType === "Hyperlocal" ? HandlepopupAddress : null
                    }
                  >
                    <div className="location-title">
                      {ecommerceType === "Hyperlocal"
                        ? travelTime
                          ? `Delivery in ${travelTime} minutes`
                          : "Delivering to"
                        : "Delivering to"}
                    </div>

                    {ecommerceType === "Hyperlocal" ? (
                      <div className="d-flex align-items-center">
                        <div className="location-name">
                          {LocationAddress
                            ? LocationAddress
                            : localStorage.getItem("address")
                            ? localStorage.getItem("address")
                            : ""}
                        </div>
                        <MdArrowDropDown size={24} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <form className="ml-auto search-form" onSubmit={handleSubmit}>
                    <NavLink
                      to="/s"
                      className="position-relative text-decoration-none"
                    >
                      <div className="search-div">
                        <MdSearch className="search-icon" />
                        <input
                          type="text"
                          className="form-control placeholderEle"
                          placeholder="Enter the product Name"
                          value={query}
                          onChange={handleInputChange}
                        />
                      </div>
                    </NavLink>
                  </form>
                  <div className="nav-content">
                    <ul className="navbar-nav">
                      {LoggedIn ? (
                        <li className="nav-item dropdown">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Account
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <a className="dropdown-item">
                                <p>My Account</p>
                                <p>{localStorage.getItem("number")}</p>
                              </a>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <NavLink
                                to="/account/orders"
                                className="dropdown-item"
                                onClick={() => ActiveData("/account/orders")}
                              >
                                My Orders
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/account/addresses"
                                className="dropdown-item"
                                onClick={() => ActiveData("/account/addresses")}
                              >
                                Saved Address
                              </NavLink>
                            </li>
                            {/* <li><NavLink to="/account/wallet" className="dropdown-item" onClick={() => ActiveData("/account/wallet")}>Wallet</NavLink></li> */}
                            <li>
                              <NavLink
                                to="/logout"
                                className="dropdown-item"
                                onClick={() => ActiveData("/logout")}
                              >
                                Log Out
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      ) : (
                        <button
                          className="btn btn-default login-btn"
                          onClick={handleLoginShow}
                        >
                          Login
                        </button>
                      )}
                    </ul>
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <IoIosHeartEmpty
                          className="nav-heart-icon"
                          onClick={openWishlistPopup}
                        />
                      </li>
                    </ul>
                    <ul className="navbar-nav  mb-2 mb-lg-0">
                      <li className="nav-item">
                        <div
                          className="btn btn-success cart-container show-desktop"
                          onClick={handleShow}
                        >
                          <div className="cart-icon-holder">
                            <MdShoppingCart />
                          </div>
                          <div className="cart-qty">
                            {cart.products.length > 0 ? (
                              <p>{cart.products.length} items</p>
                            ) : (
                              <p>My Cart</p>
                            )}
                            <span className="p-price">₹{total.toFixed(2)}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
            <div className="navbar-bottom"></div>
          </div>
        </>
      )}
      <>
        {cart.products.length > 0 && (
          <div
            className="btn btn-success cart-container show-tablet"
            onClick={handleShow}
          >
            <div className="cart-icon-holder">
              <MdShoppingCart />
            </div>
            <div className="cart-qty">
              {cart.products.length > 0 ? (
                <p>{cart.products.length} items</p>
              ) : (
                <p>My Cart</p>
              )}
            </div>
            {console.log(Order, "inside navbar")}
            <span className="p-price">
              ₹
              {parseFloat(
                Totalamount.Total_amount -
                  Order.packing_charges -
                  Order.delivery_charges
              ).toFixed(2)}
            </span>
          </div>
        )}
      </>
      <ViewCartModal
        count={count}
        setCount={setCount}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        orderAddress={orderAddress}
        setOrderAddress={setOrderAddress}
        setOrders={setOrders}
        Order={Order}
        ProductData={ProductData}
        setTotalAmount={setTotalAmount}
        products={products}
        ChangeAddress={ChangeAddress}
        GetTheAddress={GetTheAddress}
        PayMentNavigate={PayMentNavigate}
        handleLoginShow={handleLoginShow}
        cart={cart}
        Totalamount={Totalamount}
        isMobile={isMobile}
        MovetoCart={MovetoCart}
        HandleAddress={HandleAddress}
        storeNull={storeNull}
        Address={Address}
        GetAddress={GetAddress}
        travelTime={travelTime}
      />
      <Modal
        size="sm"
        show={smShow}
        onHide={HndleSmallShow}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
      >
        <Modal.Body>
          {Error ? (
            <>
              {" "}
              <div style={{ color: "red" }}>{Error}</div>
            </>
          ) : NoStock.length >= 1 ? (
            NoStock.map((res, i) => {
              console.log(res);
              return (
                <div key={i} className="p-avalibile">
                  {res.name}
                  <br />
                </div>
              );
            })
          ) : (
            <div className="p-avalibile">{NoStock.name}</div>
          )}
          {!errorMsg && (
            <div className="not-avalible text-center">
              Not Available in Selected Store
            </div>
          )}
          {errorMsg && <div className="min-value-error">{errorMsg}</div>}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={HndleSmallShow}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>
      {loginShow && (
        <LoginModal
          setLoginType={setloginShow}
          LoginType={loginShow}
          setLoggedIn={setLoggedIn}
          LoggedIn={LoggedIn}
        />
      )}
      {/* {
                popupType === "success" &&
                <AddressModal popupType={popupType} newAddressAdded={newAddressAdded} setPopupType={setPopupType} Location={localStorage.getItem("storeId") ? false : true} />
            } */}
      <AddAddress
        addressModal={addressModal}
        closeAddressModal={closeAddressModal}
        MastersModal={MastersModal}
        GetData={GetData}
        formSubmit={formSubmit}
        setPopupType={setPopupType}
        Error={Error}
      />
      {LocationpopupType === "success" && (
        <LocationModal
          LocationpopupType={LocationpopupType}
          setLocationpopupType={setLocationpopupType}
          setLocationAddress={setLocationAddress}
          SetOnline={SetOnline}
        />
      )}
      {NoAccess === "success" && (
        <AccessDeny NoAccess={NoAccess} setNoAccess={setNoAccess} />
      )}
      {popupType != "" && (
        <PopupModal
          popupType={popupType}
          setPopupType={setPopupType}
          error={Error}
          Message={message}
        />
      )}

      {siteData && <HomeMeta siteData={siteData} />}
    </>
  );
};
export { Navbar };
