import React, { useState, useEffect } from "react";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer/Footer";
import axiosConfig from "../../Service/axiosConfig";
import { BreadCrumbs } from "../BreadCrums";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import "./PrivacyPages.css";
const AboutUs = () => {
  const [formData, setFormData] = useState(null);
  const site_data = JSON.parse(localStorage.getItem("site_data"));


  const GetData = async () => {
    try {
      const responce = await axiosConfig.get(`/cms/aboutus/`);
      setFormData(responce.data.results[0])
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    GetData()
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  useEffect(() => {
    if (site_data) {
      // Create description meta tag
      const Description = document.createElement('meta');
      Description.setAttribute('property', 'description');
      Description.setAttribute('content', site_data.meta_description);
      { console.log(site_data.meta_description) }
      document.head.appendChild(Description);

      return () => {
        document.head.removeChild(Description);

      }
    }
  }, [])
  return (
    <>
      <Navbar />
      <BreadCrumbs active_page={"About Us"} />
      <div className="container pt-3">
        {/* {formData.about_us && <div dangerouslySetInnerHTML={{ __html: formData.about_us }} />} */}
        {formData &&
          <>
            <section className="grid_3_2 about-py">
              <div>
                <span className="red-title">{formData?.hash_tag}</span>
                <h1>{formData?.primary_heading}</h1>
                {/* <p className="about-top">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p> */}
                <p dangerouslySetInnerHTML={{ __html: formData?.primary_description }}></p>
                <div className="grid-3">
                  <img src={formData?.sub_image1?.image} alt="content-img" />
                  <img src={formData?.sub_image2?.image} alt="content-img" />
                  <img src={formData?.sub_image3?.image} alt="content-img" />
                </div>
              </div>
              <div className="about-img">
                <img src={formData?.main_image?.image} alt="main-image" className="img-fluid" />
              </div>
            </section>
            <section className="desc-main">
              <div className="about-desc">
                <img src={require("./images/quotes.png")} alt="quotes" />
                <h1>{formData?.secondary_heading}</h1>
                <p>{formData?.secondary_description}</p>
              </div>
            </section>
            <section className="about-py text-center">
              <h1>{formData?.third_heading}</h1>
              <p>{formData?.third_description}</p>
              <ul className="list-btn-parent">
                <li className="list-btn"><MdKeyboardDoubleArrowRight size={25} /> {formData?.point1}</li>
                <li className="list-btn"><MdKeyboardDoubleArrowRight size={25} /> {formData?.point2}</li>
                <li className="list-btn"><MdKeyboardDoubleArrowRight size={25} /> {formData?.point3}</li>
                <li className="list-btn"><MdKeyboardDoubleArrowRight size={25} /> {formData?.point4}</li>
                <li className="list-btn"><MdKeyboardDoubleArrowRight size={25} /> {formData?.point5}</li>
              </ul>
            </section>
          </>
        }
      </div>
      <Footer />
    </>
  )
}
export { AboutUs }