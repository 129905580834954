import React, { useEffect } from 'react'
import {Navigate,Outlet, useLocation, useNavigate} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { clearCart } from '../redux/Slices/cartSlice';
const Logout=()=>{
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(()=>{
        localStorage.removeItem("token")
        localStorage.removeItem('userId');
        localStorage.removeItem('number');
        // localStorage.removeItem('address');
        localStorage.removeItem('address_id');
        localStorage.removeItem('traveltime');
        localStorage.removeItem('wishlists');
        localStorage.removeItem('total');
        localStorage.removeItem('orderId');
        dispatch(clearCart());
        return Navigate("/")
    },[])
}

export default Logout;