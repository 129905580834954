import React, { useState, useEffect } from "react";
import "./DisplayCategories.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink } from "react-router-dom";
import { Products } from "../Products";

const DisplayCategories = ({Setlogin,LoginAccount}) => {
    const[loading,setLoading]=useState(false)
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get("/catlog/displaycategories/?page_size=10&is_suspended=false&subcategory__category__is_suspended=false")
                        SetData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        GetData();
    }, [])

    return (
        <>
           <div className="">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('../images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                <div className="display-categories">
                    
                    { Data.length >= 1 &&
                        Data.map((itemObj,index)=>{
                            return (
                                <Products key={itemObj.id} products_for={itemObj.subcategory.name} filter_id={itemObj.subcategory.id} product_slug={itemObj.subcategory.slug} Setlogin={Setlogin} LoginAccount={LoginAccount}/>
                            )
                        })
                    }
                </div>
                )}
           </div>
        </>
    )
}
export { DisplayCategories };