import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, InfoWindow, LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
const librariesArray = ["places"];
const AddressModal = ({ setFormData, formData, readOnly }) => {
    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchBox, setSearchBox] = useState(null);
    const [places, setPlaces] = useState([]);
    const onLoad = (ref) => {
        setSearchBox(ref);
    };

    const onPlacesChanged = async () => {
        const newPlaces = searchBox.getPlaces();
        let latitude = newPlaces[0].geometry.location.lat();
        let longitude = newPlaces[0].geometry.location.lng();
        let pincode = await extractPincode(latitude, longitude);
        const updatedPlaces = newPlaces.map((place) => ({
            name: place.name,
            location: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            },
            pincode: pincode,
        }));
        setSelectedLocation(updatedPlaces[0].location);
        console.log(updatedPlaces, "updatedPlaces");
        let formattedAddress = newPlaces[0].formatted_address;
        console.log(formattedAddress, formData, "formattedAddress");
        setFormData({
            ...formData,
            address: formattedAddress,
            latitude: updatedPlaces[0].location.lat,
            longitude: updatedPlaces[0].location.lng,
            pincode: pincode, 
            landmark:''
        });
        setPlaces(updatedPlaces);
    };

    const extractPincode = async (latitude, longitude) => {
        try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ&libraries=places`);
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                const addressComponents = data.results[0].address_components;
                for (let component of addressComponents) {
                    if (component.types.includes('postal_code')) {
                        return component.long_name;
                    }
                }
            }
            return '';
        } catch (error) {
            console.error('Error fetching postal code:', error);
            return '';
        }
    };

    const handleMapClick = (e) => {
        const lat = e.latLng.toJSON().lat;
        const lng = e.latLng.toJSON().lng;
        setSelectedLocation({ lat, lng });
        console.log('Selected Location:', { lat, lng });
        const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ&libraries=places`;
        fetch(geocodingApiUrl)
            .then(response => response.json())
            .then(data => {
                if (data.results && data.results.length > 0) {
                    const address = data.results[0].formatted_address;
                    console.log({ ...formData, latitude: lat, longitude: lng, address });
                    setFormData({ ...formData, latitude: lat, longitude: lng, address });
                    setSearchQuery(data.plus_code.compound_code);
                }
            })
            .catch(error => {
                console.error('Error fetching address:', error);
            });
    };
    useEffect(() => {
        if (formData.latitude && formData.longitude) {
            setSelectedLocation({ lat: formData.latitude, lng: formData.longitude });
        }
        else {
            setSelectedLocation({
                lat:
                    17.387140, lng:
                    78.491684
            })
        }
    }, [formData])
    return (
        <div className="">
            <LoadScript
                googleMapsApiKey="AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ"
                libraries={librariesArray}
            >
                <StandaloneSearchBox
                    onLoad={onLoad}
                    onPlacesChanged={onPlacesChanged}
                >
                    <input
                        type="text"
                        placeholder="Search for a place"
                        className="form-control"
                    />
                </StandaloneSearchBox>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '250px' }}
                    center={selectedLocation || { lat: 17.387140, lng: 78.491684 }}
                    zoom={13}
                    onClick={readOnly ? null : handleMapClick}
                    clickable={!readOnly}
                    draggable={!readOnly}
                >
                    {selectedLocation && (
                        <Marker position={selectedLocation}>
                            <InfoWindow position={selectedLocation}>
                                <div>Selected Location</div>
                            </InfoWindow>
                        </Marker>
                    )}
                </GoogleMap>
            </LoadScript>
        </div >
    )
}
export { AddressModal }