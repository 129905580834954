import React, { useState, useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { MobileNavBar } from "../Components/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineEditLocation } from "react-icons/md"
import { AiOutlineReconciliation, AiOutlineUser } from "react-icons/ai"
import { BiRupee } from "react-icons/bi"
import axiosConfig from "../Service/axiosConfig";
import { GrAddCircle } from "react-icons/gr"
import { AiOutlineHome } from "react-icons/ai"
import { AddressModal, PopupModal } from "../Components/PopupModal";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { MdWorkOutline, MdOtherHouses } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { FormParser } from "../Components";
import Masters from "../Components/masters.json";
import AddAddress from "../Components/PopupModal/AddAddress";
const MyAddress = () => {
  const [loading, setLoading] = useState()
  const [Address, setAddress] = useState([])
  const [ActiveUrl, setActiveUrl] = useState("")
  const [popupType, setPopupType] = useState("")
  const [updateId, setUpdateId] = useState("")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [addressModal, setAddressModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [Error, SetError] = useState("");
  const [Data, setData] = useState();
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [RowId, SetRowId] = useState();
  const MastersModal = JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Hyperlocal" ? Masters.AddAddressHyperlocal : Masters.AddAddress
  let Navigate = useNavigate()
  const handleResize = () => {
    setIsMobile(window.innerWidth < 1020);
  };
  const ActiveData = (activeUrl) => {
    localStorage.setItem("activeurl", activeUrl)
  }
  const HandleNavigate = () => {
    Navigate("/account")
  }
  const GetlocaData = () => {
    const Accounturl = localStorage.getItem("activeurl")
    setActiveUrl(Accounturl)
  }
  const GetData = async () => {
    setLoading(true)
    try {
      const response = await axiosConfig.get(`/accounts/address/?user=${localStorage.getItem("userId")}&is_suspended=false`)
      setAddress(response.data.results)
      console.log("sasdas")
      setLoading(false)
    }
    catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const DeleteAlert = (id) => {
    SetRowId(id)
    SetAlertDeletePopupType("success")
  }
  const CancelAlerrt = () => {
    SetAlertDeletePopupType("error")
  }
  const HandleDelete = async (id) => {
    console.log(id)
    try {
      const response = await axiosConfig.delete(`/accounts/address/${id}/`)
      console.log(response)
      SetAlertDeletePopupType("")
      GetData()
    }
    catch (error) {
      console.log(error)
    }
  }
  const HandleEdit = async (id) => {
    try {
      const response = await axiosConfig.get(`/accounts/address/${id}/`)
      console.log(response)
      setData(response.data)
      setUpdateId(id)
      setAddressModal(true)
    } catch (error) {
      console.log(error, "error getting address")
    }
  }
  const HandleGetAddress = () => {
    GetData()
  }

  const HandleAddress = () => {
    setAddressModal(true)
  }
  const closeAddressModal = () => {
    setAddressModal(false)
    setData()
    SetError("")
  }

  const formSubmit = async (Data) => {
    console.log(Data)
    try {
      axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
      const store_response = await axiosConfig.get(`/stores/get_store/?latitude=${Data.latitude}&longitude=${Data.longitude}`);
      if (store_response.data.Message.store_id !== null) {
        const response = Data.id ?
          await axiosConfig.patch(`/accounts/address/${Data.id}/`, { ...Data, "user": localStorage.getItem("userId") }) :
          await axiosConfig.post(`/accounts/address/`, { ...Data, "user": localStorage.getItem("userId") });

        console.log(response.data);

        setPopupType("success");
        closeAddressModal()
        setMessage(Data.id ? "Address updated successfully" : "Address added successfully");
        GetData();
      } else if (store_response.data.Message.store_id === null && JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional") {
        if (Data.id) {
          const res = await axiosConfig.put(`/accounts/address/${Data.id}/`, { ...Data, "user": localStorage.getItem("userId") });
          console.log(res, "address added")
          setPopupType("success")
          closeAddressModal()
          setMessage("Address edited successfully")
          GetData()
        }
        else {
          const res = await axiosConfig.post(`/accounts/address/`, { ...Data, "user": localStorage.getItem("userId") });
          console.log(res, "address added")
          setPopupType("success")
         closeAddressModal()
          setMessage("Address Added successfully")
          GetData()
        }
      } else {
        setPopupType("")
        SetError("No service Available at this location")
        setMessage("No service Available at this location")
      }
    } catch (error) {
      console.error(error);
      SetError("Error adding or updating address");
      setPopupType("error");
    }
  };

  useEffect(() => {
    GetData();
    GetlocaData()
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  return (
    <>
      {
        isMobile ? <MobileNavBar HandleNavigate={HandleNavigate} /> : <Navbar />
      }
      {
        isMobile ?
          <div className="container my-profile_mobile">
            <div className="row">
              <div className="col-12 col-10 my-profile__right_rn">
                {
                  Address.length > 0 ? <div className="my-addresses">
                    <button type="button" onClick={HandleAddress} className="btn btn--inverted my-addresses__new-address-btn icon-add"><GrAddCircle className="mx-2" />Add New Address</button>
                    {
                      Address.map((add, i) => {
                        console.log(add)
                        return (
                          <div key={i}>
                            <div className="address-item-container">
                              <div className="address-item">
                                <div className="d-flex align-items-center gap-2">
                                  {add.adress_type === "Home" && <AiOutlineHome /> || add.adress_type === "Work" && <MdWorkOutline /> || add.adress_type === "Others" && <MdOtherHouses />}
                                  <div className="address-label">{add.adress_type}</div>
                                </div>
                                <div className="address-item__value">
                                  <span className="text--dark-gray">Address - </span>
                                  <span className="text--dark-gray">{add.name},</span>
                                  <span className="text--dark-gray">{add.flat_no},</span>
                                  <span className="text--dark-gray">{add.landmark},</span>
                                  <span className="text--dark-gray">{add.address},</span>
                                  <span className="text--dark-gray">{add.pincode}</span>
                                </div>
                                <div className="d-flex justify-content-end gap-4">
                                  <p className="Action-address" onClick={() => HandleEdit(add.id)}>Edit</p>
                                  <p className="Action-address" onClick={() => DeleteAlert(add.id)}>Delete</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div> : <div className="no-address">
                    <img src={require("./images/no-address.png")} alt="no-address" />
                    <div className="no-address__heading">
                      You have no saved addresses
                    </div>
                    <div className="no-address__sub-heading">
                      Tell us where you want your orders delivered
                    </div>
                    <button type="button" className="btn--link" onClick={() => setAddressModal(true)}>Add New Address</button>
                  </div>
                }
              </div>
            </div>
          </div> : <div className="container my-profile_rn">
            <div className="row">
              <div className="col-lg-2 col-md-2 profile-main">
                <div className="profile-nav__details-block">
                  <div class="account-profile__img"></div>
                  <div class="account-profile__phone">{localStorage.getItem("number")}</div>
                </div>
                <nav className="profile-nav__list">
                  <ul className="list-unstyled">
                    <NavLink to="/account/addresses" className={ActiveUrl === "/account/addresses" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/addresses")}><li className="item-text"><span className="mx-2"><MdOutlineEditLocation /></span>My Addresses</li></NavLink>
                    <NavLink to="/account/orders" className={ActiveUrl === "/account/orders" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/orders")}><li className="item-text"><span className="mx-2"><AiOutlineReconciliation /></span>My Orders</li></NavLink>
                    {/* <NavLink to="/account/wallet" className={ActiveUrl === "/account/wallet" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/wallet")}><li className="item-text"><span className="mx-2"><BiRupee /></span>My wallet</li></NavLink> */}
                    <NavLink to="/logout" className={ActiveUrl === "/logout" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/logout")}><li className="item-text"><span className="mx-2"><AiOutlineUser /></span>Logout</li></NavLink>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-10 col-md-10 my-profile__right_rn">
                {
                  Address.length > 0 ? <div className="my-addresses">
                    <button type="button" onClick={() => setAddressModal(true)} className="btn btn--inverted my-addresses__new-address-btn icon-add"><GrAddCircle className="mx-2" />Add New Address</button>
                    {
                      Address.map((add, i) => {
                        console.log(add)
                        return (
                          <div key={i}>
                            <div className="address-item-container">
                              <div className="address-item">
                                <div className="d-flex align-items-center gap-2">
                                  {add.adress_type === "Home" && <AiOutlineHome /> || add.adress_type === "Work" && <MdWorkOutline /> || add.adress_type === "Others" && <MdOtherHouses />}
                                  <div className="address-label">{add.adress_type}</div>
                                </div>
                                <div className="address-item__value">
                                  <span className="text--dark-gray">Address-</span>
                                  <span className="text--dark-gray">{add.name},</span>
                                  <span className="text--dark-gray">{add.address},</span>
                                  <span className="text--dark-gray">{add.landmark},</span>
                                  <span className="text--dark-gray">{add.flat_no},</span>
                                  <span className="text--dark-gray">{add.pincode}</span>
                                </div>
                                <div className="d-flex justify-content-end gap-4">
                                  <p className="Action-address" onClick={() => HandleEdit(add.id)}>Edit</p>
                                  <p className="Action-address" onClick={() => DeleteAlert(add.id)}>Delete</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div> : <div className="no-address">
                    <img src={require("./images/no-address.png")} alt="no-address" />
                    <div className="no-address__heading">
                      You have no saved addresses
                    </div>
                    <div className="no-address__sub-heading">
                      Tell us where you want your orders delivered
                    </div>
                    <button type="button" className="btn--link" onClick={() => setAddressModal(true)}>Add New Address</button>
                  </div>
                }
              </div>
            </div>
            {popupType != "" &&
              <PopupModal popupType={popupType} setPopupType={setPopupType} Message={message} />
            }
          </div>
      }
      <AddAddress  addressModal={addressModal} closeAddressModal={closeAddressModal} MastersModal={MastersModal} GetData={GetData} formSubmit={formSubmit}  setPopupType={setPopupType} Data={Data}  setData={setData} Error={Error} />
      {
        AlertDeletePopupType === "success" &&
        <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
      }
    </>
  )
}
export { MyAddress }