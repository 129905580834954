import { React, useState, useEffect, } from "react";
import { useNavigate } from "react-router-dom";
import "../Pages/Brands.css";
import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components";
import axiosConfig from "../Service/axiosConfig";
const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const SearchBrands =  (name) => {
    navigate(`/s/?query=${name}`);
    
  }
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axiosConfig.get("/catlog/brands/?page_size=1000");
        setBrands(response.data.results);
        setLoading(false);
      } catch {
        setError(error);
        setLoading(true);
      }
    };
    fetchBrands();
  }, []);
  
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="brand-heading">Brands</h1>
          </div>
        </div>
        <div className="brand-card">
          {brands.length > 0 &&
            brands.map((brand) => (
              <span className="brand-name" onClick={() => SearchBrands(brand.brand_name)}>{brand.brand_name}</span>
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
};
export { Brands };
