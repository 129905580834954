import React from 'react';
import { Link } from 'react-router-dom';
import "./index.css"
const BreadCrumbs = ({active_page,prev_sub_page,sub_page}) => {
    console.log(active_page,prev_sub_page,sub_page)
    const linkTo = prev_sub_page ? `/${prev_sub_page}/${sub_page}` : `/${sub_page}`;
    return (
        <>
            <section className="breadcrumb-section text-center">
                <nav aria-label="breadcrumb">
                {/* <h1 className='p-title'>{active_page}</h1> */}
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        {prev_sub_page &&
                            <li className="breadcrumb-item"><Link to={`/${prev_sub_page}`}>{prev_sub_page}</Link></li>
                        }
                        {sub_page &&
                            <li className="breadcrumb-item"><Link to={linkTo}>{sub_page}</Link></li>
                        }
                        <li className="breadcrumb-item active" aria-current="page">{active_page}</li>
                    </ol>
                </nav>
            </section>
        </>
    );
};

export  {BreadCrumbs};