import React, { useState, useEffect } from "react";
import { NotFound } from "../../Pages";
import axiosConfig from "../../Service/axiosConfig"
import Select from 'react-select';
import { MdOutlineEdit } from "react-icons/md"
// import { ReactQuillComponent } from "./reactquill/reactquill";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdInfo } from 'react-icons/md';
import { AddressModal } from "../PopupModal";
import { BsFillCloudUploadFill } from "react-icons/bs"
// import { FileManager } from "../FileManager/FileManager";
// import { MultiFilemanager } from "./mutlipleImages";
const FormParser = ({ modelObject, formData, formSubmit, error, button, buttonTitle, readOnly }) => {
    const [Data, SetData] = useState({})
    const [file_name, setFile_name] = useState("")
    const [loading, setLoading] = useState(true);
    const [dynamicOptions, SetDynamicOptions] = useState({})
    const [DataFilter, SetDataFilter] = useState([])
    const [Option, setOption] = useState({});
    const [Error, SetError] = useState("")
    const [Nextbutton, setNextbutton] = useState("")
    const [modalShow, setModalShow] = useState(false)
    const [FieldErrors, setFieldErrors] = useState({});
    const GetData = async () => {
        try {
            for (const field_obj of field_objects) {
                if (field_obj.fieldType === "dynamicdropdown") {
                    const response = await axiosConfig.get(field_obj.options_url);
                    const count = Math.ceil(response.data.count / 20);
                    SetDynamicOptions(prevOptions => ({
                        ...prevOptions,
                        [field_obj.fieldName]: response.data.results
                    }));
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleAutoSlug = (e) => {
        const { name, value } = e.target
        const notvalidslug = value;
        const trimmedSlug = notvalidslug.trim();
        const slug = trimmedSlug.replace(/\s+/g, '-').toLowerCase();
        { formData.slug === "" ? SetData({ ...Data, [name]: value, slug: slug }) : SetData({ ...Data, [name]: value }) }
    };

    const GetMultiData = async () => {
        let data = []
        try {
            for (const field_obj of field_objects) {
                if (field_obj.fieldType === "dynamicdropdown" || field_obj.fieldType === "dynamicMultiSelectdropdown") {
                    const response = await axiosConfig.get(field_obj.options_url);
                    const count = Math.ceil(response.data.count / 20);
                    SetDynamicOptions(prevOptions => ({
                        ...prevOptions,
                        [field_obj.fieldName]: response.data.results
                    }));
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    // const GetDynamicData = (id) => {
    //     console.log(id)
    //     let data=[]
    //     field_objects.forEach((field_obj, ind) => {
    //         if (field_obj.fieldType === "dynamicSelectdropdown") {
    //             axiosConfig.get(`${field_obj.options_url}${id}/`).then(response => {
    //                 SetSelectedOptions({ ...SelectedOptions, [field_obj.fieldName]: response.data })
    //                 SelectedOptions[field_obj.fieldName] = response.data
    //                 console.log(response.data)
    //             }).catch((error) => {
    //                 console.log(error)
    //             })
    //         }
    //     })
    // }
    const HandleRadioChange = (e, ItemName) => {
        const { name } = e.target
        SetData({ ...Data, [name]: ItemName });
    }
    const [SelectDynamicOptions, SetSelectDynamicOptions] = useState([])
    const SelectOptions = (id) => {
        field_objects.forEach(item => {
            if (item.fieldType === "dynamicSelectdropdown") {
                console.log(id, `${item.options_url}${id}`)
                let url = `${item.options_url}${id}`
                axiosConfig.get(url).then(res => {
                    console.log(res.data.results)
                    SetSelectDynamicOptions(res.data.results)
                }).catch(error => {
                    console.log(error)
                })
            }
        })
    }
    const editDataRender = async (formData) => {
        field_objects.forEach(async (field_obj, idx) => {
            if (formData) {
                Data[field_obj.fieldName] = formData[field_obj.fieldName]
                SetData(formData)
                if (field_obj.fieldType === "dynamicSelectdropdown") {
                    if (formData[field_obj.renderField]) {
                        let url = `${field_obj.options_url}${formData[field_obj.renderField]}`
                        console.log(url)
                        await axiosConfig.get(url).then(res => {
                            SetSelectDynamicOptions(res.data.results)
                            const find = res.data.results.find(find => find.id === formData[field_obj.fieldName])
                            setOption({ value: find.id, label: find[field_obj.displayField] })
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                }
                if (field_obj.fieldType === "dynamicMultiSelectdropdown") {
                    let data = []
                    formData && formData[field_obj.fieldName] && formData[field_obj.fieldName].forEach(value => {
                        console.log(value, dynamicOptions)
                        const findData = dynamicOptions[field_obj.fieldName].find(find => find.id === value)
                        data.push(findData)
                    })
                    setSelectedOptions(data)
                }
            }
        })
        setTimeout(function () {
            setLoading(false);
        }.bind(this), 500)
    }
    const [selectedOptions, setSelectedOptions] = useState([])
    console.log(selectedOptions)
    useEffect(() => {
        GetData();
        GetMultiData()
        editDataRender(formData)
    }, [formData])
    console.log(modelObject)
    const [FilterData, SetFilterdata] = useState([])
    const [Name, SetName] = useState("")
    const HandleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        SetData({ ...Data, [name]: value })
        validateField(name, value)
        SetName(value)
    }
    const handleSelectChange = (selectedOption, name) => {
        console.log(selectedOption.value, name)
        SetData({ ...Data, [name]: selectedOption.value });
        SelectOptions(selectedOption.value)
    };
    const handleMultiSelectChange = (selectedOption, Value, name) => {
        let data = []
        selectedOption.forEach(res => {
            data.push(res.value)
        })
        console.log(data)
        SetData({ ...Data, [name]: data });
    };
    const HandleFiles = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        SetData({ ...Data, [name]: value })
    }
    const HandleMultiPulFiles = (e) => {
        e.preventDefault()
        const file = e.target.files;
        const name = e.target.name;
        const value = file;
        SetData({ ...Data, [name]: value })
    }
    const OnSubmit = (e) => {
        e.preventDefault();
        const requiredFieldErrors = validateRequiredFields();

        if (requiredFieldErrors) {
            console.log(requiredFieldErrors)
            setFieldErrors(requiredFieldErrors);
            return;
        }

        // Reset field-level errors
        setFieldErrors({});
        formSubmit(Data, setFieldErrors);
    }

    console.log(Data)
    const validateField = (name, value) => {
        const regex = {
            country_name: /^[a-zA-Z]+$/,
            state_name: /^[a-zA-Z]+$/,
            city_name: /^[a-zA-Z]+$/,
            pincode: /^[0-9]+$/,
            email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            cpt_code: /^[0-9]+$/,
        };
        const isInputValid = regex[name] ? regex[name].test(value) : true;
        const fields = field_objects.find(fields => fields.fieldName === name)
        if (!isInputValid) {
            let name = fields.fieldName;
            setFieldErrors({ [name]: `Enter a valid ${fields.label}` })
        }
        else {
            setFieldErrors({})
        }
    }
    let field_objects = []
    modelObject.orderBy.forEach((order_obj, idx) => {
        modelObject.fields.forEach((field_obj, index) => {
            if (field_obj.fieldName === order_obj) {
                let flagAddClass = true
                modelObject.layoutSpecificFieldClasses.forEach((classObj, iex) => {
                    if (classObj[field_obj.fieldName]) {
                        field_obj['groupClass'] = classObj[field_obj.fieldName]
                        flagAddClass = false
                    }
                })
                if (modelObject.layoutClass && flagAddClass) {
                    field_obj['groupClass'] = modelObject.layoutClass
                }
                field_objects.push(field_obj)
            }
        })
    })
    const validateRequiredFields = () => {
        const requiredFieldErrors = {};

        field_objects.forEach((fieldItem) => {
            if (fieldItem.required && !Data[fieldItem.fieldName]) {
                requiredFieldErrors[fieldItem.fieldName] = `${fieldItem.label} is required`;
            }
        });

        return Object.keys(requiredFieldErrors).length > 0 ? requiredFieldErrors : null;
    };
    const handleImageChange = (files, fieldName) => {
        console.log(files)
        const file = files[0];
        SetData({ ...Data, [fieldName]: file });
    };
    const handleMultiImageChange = (files, fieldName) => {
        console.log(files)
        const file = files;
        SetData(prevData => ({
            ...prevData,
            [fieldName]: [...(prevData[fieldName] || []), ...file],
        }));
    };

    const [ismultiple, setIsMulti] = useState()
    // const Handlefilemanager = (name, ismultiple) => {
    //     if (ismultiple) {
    //         setFile_name(name)
    //         setIsMulti(true)
    //         setModalShow(true)
    //     }
    //     else {
    //         setFile_name(name)
    //         setModalShow(true)
    //     }
    // }
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        SetData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    return (
        <>
            <div className="container-form">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('./images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <form onSubmit={OnSubmit} encType="multipart/form-data">
                        <div className="Loginerror">
                            <p>{error}</p>
                        </div>
                        <div className="row">
                            {field_objects &&
                                field_objects.map((fieldItem, i) => {
                                    const isRequired = fieldItem.required ? '*' : '';
                                    const hasError = Error && Error.includes(`Enter a valid ${fieldItem.label}`);
                                    const isRequiredAndEmpty = fieldItem.required && !Data[fieldItem.fieldName];
                                    return (
                                        <div key={fieldItem.fieldName} className={fieldItem.groupClass}>
                                            {fieldItem.fieldType === "string" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.label} <span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        {fieldItem.auto_slug ?
                                                            <input type="text" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={handleAutoSlug} value={Data[fieldItem.fieldName]} disabled={fieldItem.always_read_only ? true : readOnly} />
                                                            :
                                                            <input type="text" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} value={Data[fieldItem.fieldName]} disabled={fieldItem.always_read_only ? true : readOnly} />
                                                        }
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {fieldItem.fieldType === "stringWithrightText" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.add_label ? fieldItem.add_label : ""}-{fieldItem.label} <span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="text" className={`form-control class-border custom-input${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error class-border custom-input') ? 'invalid-error class-border custom-input' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readOnly} />
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-append-custom border-left-0">{fieldItem.right_text}</span>
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {
                                                fieldItem.fieldType === "codewithbackground" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <div className="color-box">
                                                            <div
                                                                style={{
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    borderRadius: '5px',
                                                                    backgroundColor: Data[fieldItem.fieldName],
                                                                }}
                                                            ></div>
                                                        </div>
                                                        <input type="text" className={`form-control custom-input-class custom-input${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error class-border custom-input') ? 'invalid-error class-border custom-input' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>

                                            }
                                            {fieldItem.fieldType === "email" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="email" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {fieldItem.fieldType === "url" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="nuput-box">
                                                        <input type="url" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {fieldItem.fieldType === "boolean" &&
                                                <div className="form-group">
                                                    <label className="form-check-label ari-radio-label">
                                                        <input className="ari-radio" type="checkbox" name={fieldItem.fieldName} value="true" onChange={HandleChange} required={fieldItem.required} disabled={readOnly} /> {fieldItem.label}
                                                    </label>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <OverlayTrigger
                                                            placement="right"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    Tooltip for <strong>{fieldItem.label}</strong>.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button style={{ border: "none", background: "#FFF" }}>
                                                                <MdInfo />
                                                            </button>
                                                        </OverlayTrigger>
                                                    )}
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "file" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    {Data && Data[fieldItem.fieldName] &&
                                                        <a href={Data[fieldItem.fieldName]} target="_blank">
                                                            <img src={Data[fieldItem.fieldName]} width="30" className="form-edit-img" />
                                                        </a>
                                                    }
                                                    <div className="input-box">
                                                        <input type="file" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleFiles} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                            {fieldItem.fieldType === "date" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="date" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "time" &&
                                                <div className="form-group">

                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="time" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "integer" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="number" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} value={Data[fieldItem.fieldName]} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "password" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="password" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "radio" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <br />
                                                    {fieldItem.options &&
                                                        fieldItem.options.map((optionItem, index) => {
                                                            return (
                                                                <div className="form-check-inline" key={index}>
                                                                    <label className="form-check-label ari-radio-label">
                                                                        <input type="radio" className="ari-radio" name={fieldItem.fieldName} checked={Data[fieldItem.fieldName] === optionItem} defaultValue={Data[fieldItem.fieldName]} onChange={(e) => HandleRadioChange(e, optionItem)} required={fieldItem.required} disabled={readOnly} />{optionItem}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {/* {fieldItem.required && (
                                                        <OverlayTrigger
                                                        placement="right"
                                                        overlay={
                                                          <Tooltip id="tooltip-top">
                                                            Tooltip for <strong>{fieldItem.label}</strong>.
                                                          </Tooltip>
                                                        }
                                                      >
                                                        <button style={{border:"none",background:"#FFF"}}>
                                                        <MdInfo />
                                                        </button>
                                                      </OverlayTrigger>
                                                    )} */}
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "textarea" &&
                                                <div className="form-group">
                                                    <label>{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <textarea className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} rows="2" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} value={Data[fieldItem.fieldName]} disabled={readOnly}></textarea>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                            {
                                                fieldItem.fieldType === "dropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <div>
                                                            <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={fieldItem.options} placeholder={fieldItem.label} defaultValue={fieldItem.options.find(res => res.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readOnly} />
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    {/* <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange}>
                                                        <option value="">SELECT {fieldItem.label}</option>
                                                        {
                                                            dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((optionItem, i) => {
                                                                return (
                                                                    <option value={optionItem.id} key={i} selected={optionItem.id === Data[fieldItem.fieldName]}>{optionItem[fieldItem.displayField]}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>  */}
                                                    <div className="input-box">
                                                        <div>
                                                            <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                                { "value": res.id, "label": res[fieldItem.displayField] }
                                                            ))} placeholder={fieldItem.label} value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(res => res.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readOnly} />
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                            {
                                                fieldItem.fieldType === "dynamicSelectdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    {console.log(Data[fieldItem.fieldName])}
                                                    <div className="input-box">
                                                        <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={SelectDynamicOptions && SelectDynamicOptions.map((res) => (
                                                            { "value": res.id, "label": res[fieldItem.displayField] }
                                                        ))} placeholder={fieldItem.label} value={SelectDynamicOptions && SelectDynamicOptions.map((res) => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(find => find.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicMultiSelectdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}:</label>
                                                    <Select isMulti options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                        { "value": res.id, "label": res[fieldItem.displayField] }
                                                    ))} placeholder={fieldItem.label}
                                                        value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ value: res.id, label: res[fieldItem.displayField] })).filter(option => (Data[fieldItem.fieldName] || []).includes(option.value))}
                                                        onChange={(selectedOption) => handleMultiSelectChange(selectedOption, selectedOption.value, fieldItem.fieldName)} required={fieldItem.required} />
                                                </div>
                                            }


                                            {/* {
                                            fieldItem.fieldType === "DynamicSelectOptions" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label}:</label>
                                                <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange}>
                                                    <option value="">SELECT {fieldItem.label}</option>
                                                    {
                                                       SelectDynamicOptions.map((optionItem, i) => {
                                                            return (
                                                                <option value={optionItem.id} key={i} selected={optionItem.id===Data[fieldItem.fieldName]?Data[fieldItem.fieldName]:undefined} >{optionItem[fieldItem.displayField]}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>
                                        } */}
                                            {fieldItem.fieldType === "mulitipulFiles" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                <input className="form-control" type="file" id="formFileMultiple" name={fieldItem.fieldName} multiple onChange={HandleMultiPulFiles} required={fieldItem.required} disabled={readOnly} />
                                            </div>
                                            }
                                            {fieldItem.fieldType === "status" && <div key={i} className="form-group form-check form-switch">
                                                <div>
                                                    <label className="form-check-label">{fieldItem.label}</label>
                                                </div>
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={handleCheckboxChange} disabled={readOnly} />

                                            </div>
                                            }

                                            {
                                                fieldItem.fieldType === "ReactQuill" &&
                                                <div className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        {/* <ReactQuillComponent className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} SetData={SetData} Data={Data} name={fieldItem.fieldName} value={Data[fieldItem.fieldName]} required={fieldItem.required} /> */}
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "subheading" &&
                                                <div className="form-group">
                                                    {console.log(Data[fieldItem.fieldName])}
                                                    <h6>{fieldItem.label}</h6>
                                                </div>
                                            }
                                            {fieldItem.fieldType === "map" &&
                                                <div className="form-group">
                                                    <AddressModal setFormData={SetData} formData={Data} readOnly={readOnly} />
                                                </div>
                                            }
                                            {/* {
                                                fieldItem.fieldType === "filemanager" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>

                                                    <div className="banner-image">
                                                        <div className={`image-field ${hasError || (FieldErrors[fieldItem.fieldName] && 'active') ? 'active' : ''}`} style={{ 'width': '100%' }}>
                                                            <label className="image-container" htmlFor="imageInput" onClick={!readOnly ? () => Handlefilemanager(fieldItem.fieldName) : null}>
                                                                {Data[fieldItem.fieldName] ? (
                                                                    <img

                                                                        className="image"
                                                                        src={Data[fieldItem.fieldName] && Data[fieldItem.fieldName]["image"]}
                                                                        alt={Data[fieldItem.fieldName]}
                                                                    />
                                                                ) : (
                                                                    <label htmlFor="imageInput" className="image-icon">
                                                                        <BsFillCloudUploadFill className="upload-icon" />
                                                                        <p className="textfile">Choose a file</p>
                                                                    </label>
                                                                )}
                                                            </label>
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            } */}
                                            {/* {
                                                fieldItem.fieldType === "multifilemanager" &&
                                                <MultiFilemanager formData={Data} setFormData={SetData} Handlefilemanager={Handlefilemanager} />
                                            } */}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {!readOnly &&
                            <button type="submit" className={`btn btn-primary ${button ? button : ""}`}>{buttonTitle ? buttonTitle : "Submit"}</button>
                        }
                        {/* {
                            modalShow &&
                            <FileManager modalShow={modalShow} setModalShow={setModalShow} handleFile={ismultiple ? handleMultiImageChange : handleImageChange} file_name={file_name} ismultiple={ismultiple ? true : false} value="image_key" />
                        } */}

                    </form>
                )}
            </div>

        </>
    )
}
export { FormParser }