import React, { useEffect } from "react"
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { StaffLogin, DashboardLayout, FriendlyUrlComponent, PrivacyPage, TermsAndServices, AboutUs, ContuctUs } from "./Components"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from "./Utils/ProtectedRoute"
import { Home, Dashboard, ProductSearch, CategoriesPage, SubCategoriesPage, NotFound, MyOrders, MyOrderDetails, MyAddress, Wallet, SearchProducts, Checkout } from "./Pages"
import Logout from "./Utils/Logout";
import { AccountResp } from "./Components/Navbar";
import { Wishlistpage } from "./Pages/Wishlistpage";
import { fetchProducts } from "./redux/Slices/cartSlice";
import { useDispatch } from "react-redux";
import { Faq, ReturnPolicy, Cancellation } from "./Components/PrivacyPages";
import Blog from "./Components/Footer/Blogs";
import { Brands } from "./Pages/Brands";

import ReactGA from "react-ga4";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch the fetchProducts action when the component mounts
    dispatch(fetchProducts());
    //   document.head.insertAdjacentHTML('beforeend',  JSON.parse(localStorage.getItem("site_data"))?.tag_manager);
    //    document.head.insertAdjacentHTML('beforeend', `
    //   <link rel="icon" href="${JSON.parse(localStorage.getItem("site_data"))?.favicon.image}">
    //  `);

  }, [dispatch]);

  useEffect(() => {
    if (window.location.hostname === 'opoto.in') {
      ReactGA.initialize("G-ZYPPL9YH8F");

    }
    const browser_info = {
      browser_name: navigator.userAgentData?.brands[0]?.brand,
      browser_version: navigator.userAgentData?.brands[0]?.version,
      user_agent: navigator.userAgent,
    };
    ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });
    ReactGA.event("browser_info", {
      browser_name: browser_info.browser_name,
      browser_version: browser_info.browser_version,
      user_agent: browser_info.user_agent,
    });

    // Find all style tags
    const styles = document.querySelectorAll('style');
    const head = document.querySelector('head');
    const body = document.querySelector('body');

    // Move all style tags to the bottom of the body
    styles.forEach((style) => {
      body.appendChild(style); // or append to the head as last child
    });
  }, []);

  return (

    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/logout" element={<Logout />} />
        <Route path='/product-search' element={<ProductSearch />} />
        <Route path='/s' element={<SearchProducts />} />
        <Route path='/c/:categoryslug' element={<CategoriesPage />} />
        <Route path='/c/:categoryslug/:subcategoryslug' element={<SubCategoriesPage />} />
        <Route path='/:friendlyurl/' element={<FriendlyUrlComponent />} />
        <Route path='/:categoryurl/:friendlyurl/' element={<FriendlyUrlComponent />} />
        <Route path='/:subcategoryurl/:categoryurl/:friendlyurl/' element={<FriendlyUrlComponent />} />
        <Route path="" element={<ProtectedRoute />} >
          <Route path='/account' element={<AccountResp />} />
          <Route path='/account/orders' element={<MyOrders />} />
          <Route path='/account/orders/:orderId/:address' element={<MyOrderDetails />} />
          <Route path='/account/addresses' element={<MyAddress />} />
          <Route path='/account/wallet' element={<Wallet />} />
          <Route path='/checkout' element={<Checkout />} />
        </Route>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms-services" element={<TermsAndServices />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/cancellation" element={<Cancellation />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContuctUs />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/wishlist" element={<Wishlistpage />} />
        <Route path='/wishlist/:friendlyurl' element={<FriendlyUrlComponent />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/not-found' element={<NotFound />} />
        <Route path='/brands' element={<Brands />} />
      </Routes>
    </Router>
  );
}
export default App;
