import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { Footer, Navbar } from '../Components';
import dog from '../Pages/images/dog.png';


const NotFound = () => {
    return (
        <>
        <Navbar/>
        <div className='row'>
            <div className='col-md-6'>
                <img src={dog} className='dog_png'/>
            </div>
                <div className='col-md-6'>
                <h1 className='not-found-heading'>!Oops</h1>
                <p className='lead'>The page you are looking for can’t be found.</p>
                <p className='lead-sm'>You might find these links useful</p>
                <Link to="/" className='gotohome'>Go to Home</Link>
            </div>
            </div>
        <Footer/>
        </>
    );
}


export { NotFound };
