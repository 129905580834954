import React, { useEffect, useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import axiosConfig from "../../Service/axiosConfig"
import { AccessDeny } from "./AccesssDeny";
const LocationModalNavBar = ({ setLocatioNavBarType, LocationNavBarType, setLocationAddress, Order, setOrders }) => {
  const [show, setShow] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);
  const inputRef = useRef();
  const [NoAccess, setNoAccess] = useState("")
  const HandleAccess = () => {
    setNoAccess("success")
    setShow(false)

  }
  const handlePlaceChanged = async () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      console.log(place.formatted_address)
      console.log(place.geometry.location.lat())
      console.log(place.geometry.location.lng())
      try {
        const Responce = await axiosConfig.get(`/stores/get_store/?latitude=${place.geometry.location.lat()}&longitude=${place.geometry.location.lng()}`)
        console.log(Responce.data.Message.store_id)
        if (Responce.data.Message.store_id !== null && Responce.data.Message.is_online === "online") {
          localStorage.setItem("storeId", Responce.data.Message.store_id)
          localStorage.setItem("opens_at", Responce.data.Message.opens_at);
          localStorage.setItem("closes_at", Responce.data.Message.closes_at);
          localStorage.setItem("slot_duration", Responce.data.Message.slot_duration);
          localStorage.setItem("address", Responce.data.Message.address)
          localStorage.setItem("latitude", place.geometry.location.lat())
          localStorage.setItem("longitude", place.geometry.location.lng())
          setOrders({ ...Order, "stores": Responce.data.Message.store_id })
          setLocationAddress(Responce.data.Message.address)
          setShow(false)
          window.location.reload(); // Reload the window after successful API call
        } else {
          HandleAccess()
        }
      }
      catch (error) {
        console.log(error)
      }
    }
  }

  const LocationData = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          console.log(position)
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          try {
            const Responce = await axiosConfig.get(`/stores/get_store/?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`)
            if (Responce.data.Message.store_id !== null && Responce.data.Message.is_online === "online") {
              localStorage.setItem("storeId", Responce.data.Message.store_id)
              localStorage.setItem("opens_at", Responce.data.Message.opens_at);
              localStorage.setItem("closes_at", Responce.data.Message.closes_at);
              localStorage.setItem("slot_duration", Responce.data.Message.slot_duration);
              localStorage.setItem("address", Responce.data.Message.address)
              localStorage.setItem("latitude", position.coords.latitude)
              localStorage.setItem("longitude", position.coords.longitude)
              setOrders({ ...Order, "stores": Responce.data.Message.store_id })
              setShow(false)
              setLocationAddress && setLocationAddress(Responce.data.Message.address)
              window.location.reload()
            }
            else{
              HandleAccess()
            }
          }
          catch (error) {
            console.log(error)
          }
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError('Geolocation is not supported by your browser');
    }
  }
  const handleClose = () => {
    setShow(false)
    setLocatioNavBarType("")
    console.log("ffgg")

  };
  const handleShow = () => setShow(true);
  const GetData = () => {
    if (LocationNavBarType === "success") {
      setShow(true)
      console.log("sss")

    }
    else {
      setShow(false)
    }
  }
  useEffect(() => {
    GetData()
  }, [LocationNavBarType])
  return (
    <>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="change-location-title">Change Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between">
              <button type="button" className="btn--link" onClick={LocationData}>Detect My Location</button>
              <div className="oval-container">
                <div className="oval">
                  <span className="separator-text">
                    <div className="or">
                      OR
                    </div>
                  </span>
                </div>
              </div>
              <div>
                <LoadScript googleMapsApiKey="AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ" libraries={["places"]}>
                  <StandaloneSearchBox
                    onLoad={ref => inputRef.current = ref}
                    onPlacesChanged={handlePlaceChanged}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Location"

                    />
                  </StandaloneSearchBox>
                </LoadScript>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {

          NoAccess === "success" &&
          <AccessDeny NoAccess={NoAccess} setNoAccess={setNoAccess} NavBarType={setLocatioNavBarType} setLocationAddress={setLocationAddress} />
        }
      </div>
    </>
  )
}
export { LocationModalNavBar }