import {React,useEffect} from 'react';
import { Footer, Navbar } from "..";
const Faq=()=> {
    let data=JSON.parse(localStorage.getItem("policies"));
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return (
    <>
    <Navbar />
    <div className="container pt-4">
        {data.faq && <div dangerouslySetInnerHTML={{ __html: data.faq }} />}
    </div>
    <Footer />
    </>
  )
}
export {Faq}
