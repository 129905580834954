import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { LocationModal } from "./LocationModal";
import axiosConfig from "../../Service/axiosConfig";
import { PiMapPinLight } from "react-icons/pi";
const AccessDeny = ({ NoAccess, setNoAccess, setLocationAddress }) => {
  const [Access, setAccess] = useState(false);
  const [stores, setStores] = useState([]);
  const [LocationpopupType, setLocationpopupType] = useState("")
  const handleClose = () => {
    setAccess(false)
    setNoAccess("")
  };
  const HandlePopupModal = () => {
    setAccess(false)
    setLocationpopupType("success")
  }
  const GetData = () => {
    if (NoAccess === "success") {
      setAccess(true)
    }
    else {
      setAccess(false)
    }
  }
  const GetStores = async () => {
    if (stores.length > 0) return
    try {
      const response = await axiosConfig.get(`/stores/stores/?page=1&page_size=20`)
      setStores(response.data.results)
    } catch (error) {
      console.log("Error getting stores", error)
    }
  }
  const handleStore = (store) => {
    console.log(store)
    setLocationAddress(store.address)
    setAccess(false)
    setLocationpopupType("")
    localStorage.setItem("storeId", store.id);
    localStorage.setItem("opens_at", store.opens_at);
    localStorage.setItem("closes_at", store.closes_at);
    localStorage.setItem("slot_duration", store.slot_duration);
    localStorage.setItem("address", store.address);
    localStorage.setItem("latitude", store.latitude);
    localStorage.setItem("longitude", store.longitude);
  }
  useEffect(() => {
    GetData();
    GetStores();
  }, [NoAccess])
  return (
    <>
      <div>
        <Modal
          show={Access}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <p onClick={HandlePopupModal} className="modal-back">Back</p>
            <div className="non-serviceable-step">
              <img src={require("../../Pages/images/AccessImage.png")} className="ns--image" alt="AccessImage" />
              <div className="ns-exclamation">
                Oh No!
              </div>
              <div>
                We are currently not serving in your area. We are actively working on expanding our reach and will be available in your area in the near future.
              </div>
            </div>
            <p className="modal-back">Our available stores</p>
            <div className="stores-container">
              {stores.length > 0 &&
                stores.map((store) => {
                  return (
                    <div className="stores" key={store.id} onClick={() => handleStore(store)}>
                      <PiMapPinLight size={20} className="map-pin" />
                      <div>
                        <h6>
                          {store.name}
                        </h6>
                        <p>
                          {store.address}
                        </p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </Modal.Body>
        </Modal>
        {
          LocationpopupType === "success" &&
          <LocationModal LocationpopupType={LocationpopupType} setLocationpopupType={setLocationpopupType} setLocationAddress={setLocationAddress} />
        }

      </div>
    </>
  )
}
export { AccessDeny }