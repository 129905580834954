import React, { useEffect, useState } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { MobileNavBar } from "../Components/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineEditLocation, MdArrowDropDown } from "react-icons/md"
import { AiOutlineReconciliation, AiOutlineUser } from "react-icons/ai"
import axiosConfig from "../Service/axiosConfig";
import { BiRupee } from "react-icons/bi";
import "./MyOrders.css";
const MyOrders = () => {
  const [loading, setLoading] = useState()
  const [loading2, setLoading2] = useState()
  const [Orders, setOrders] = useState([])
  const [ActiveUrl, setActiveUrl] = useState("")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  let Navigate = useNavigate();
  const HandleNavigate = () => {
    Navigate("/account")
  }
  const handleResize = () => {
    setIsMobile(window.innerWidth < 1020);
  };

  const handleScroll = async (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;
    try {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        if (page < totalPages) {
          setLoading2(true)
          let pages = page + 1
          setPage(pages)
          console.log(page, "page", pages)
          let resp = await axiosConfig.get(`/accounts/orders/?page=${pages}&user=${localStorage.getItem("userId")}&orderstatus=`)
          const filteredResults = resp.data.results.filter(order => order.orderstatus !== 'Cart');
          console.log(resp.data.results, "filtered", filteredResults)
          setOrders(prevOrders => [...prevOrders, ...filteredResults]);
          setLoading2(false)
        }
      }
    } catch (error) {
      console.log("Error in handleScroll : ", error)
    }
  }
  const ActiveData = (activeUrl) => {
    localStorage.setItem("activeurl", activeUrl)
    const Accounturl = localStorage.getItem("activeurl")
    setActiveUrl(Accounturl)
  }
  const GetlocaData = () => {
    const Accounturl = localStorage.getItem("activeurl")
    setActiveUrl(Accounturl)
  }
  const GetData = async () => {
    setLoading(true);
    try {
      const initialResponse = await axiosConfig.get(`/accounts/orders/?page=${page}&user=${localStorage.getItem("userId")}&orderstatus=`);
      const totalPages = Math.ceil(initialResponse.data.count / 10);
      setTotalPages(totalPages);
      const filteredResults = initialResponse.data.results.filter(order => order.orderstatus !== 'Cart');
      console.log(initialResponse.data.results, "filtered", filteredResults)
      setOrders(filteredResults);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  console.log(Orders)
  useEffect(() => {
    GetData();
    GetlocaData()
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  const timeString = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    return date.toLocaleDateString('en-US', options);
  }
  return (
    <>
      {
        isMobile ? <MobileNavBar HandleNavigate={HandleNavigate} /> : <Navbar />
      }
      {
        isMobile ?
          <div className="container-fluid my-profile_mobile">
            <div className="row">
              <div className="col-12 col-sm-12 order-overflow" onScroll={handleScroll}>
                <div className="sticky-container">
                  <div className="order-history">
                    <span>Ordered products history</span>
                    {/* <div className="f_13_400"><MdArrowDropDown size={24} /></div> */}
                  </div>
                </div>
                {loading ? (
                  <div className="loader-container">
                    <img src={require("../Components/images/loader.gif")} alt="loading" />
                  </div>
                ) : (
                  <>
                    {Orders.length > 0 ? (
                      Orders.map((order, index) => (
                        <div key={index}>
                          <div className="row order">
                            <div className="col-12 rowBetweenCenter">
                              <div className="rowBetweenCenter flex-1">
                                {order.orderstatus === "Cart" && <img src={require("./images/bag.png")} className="image me-2" alt="bag" />}
                                {order.orderstatus === "Placed" && <img src={require("./images/tick.png")} className="image me-2" alt="tick" />}
                                {(order.orderstatus !== "Cart" && order.orderstatus !== "Placed") && <img src={require("./images/orderimg.png")} alt="" className="image" />}
                                <div>
                                  <h5 className="order_id">Order Id: {order?.id}&nbsp;-&nbsp;₹{order.net_amount}</h5>
                                  <p className="">Placed on {timeString(order.order_date)}</p>
                                </div>
                                <div className={`status_badge mx-2 ${order.orderstatus === "Cart" || order.orderstatus === "Placed" ? "green" : "grey"}`}>
                                  <div>{order.orderstatus}</div>
                                </div>
                                <div className="text-center">
                                  <NavLink to={`${order.id}/${order.addresses?.id}`}><button type="button" className="btn btn-outline-success view-button">view details</button></NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))
                    ) : (
                      <p className="no-orders">You have not ordered anything yet!!!</p>
                    )}
                    {loading2 &&
                      <div className="loader-container">
                        <img src={require("../Components/images/loader.gif")} alt="loading" />
                      </div>
                    }
                  </>
                )}
              </div>
            </div>
          </div> :
          <div className="container my-profile_rn">
            <div className="row">
              <div className="col-lg-2 col-md-2 profile-main">
                <div className="profile-nav__details-block">
                  <div class="account-profile__img"></div>
                  <div class="account-profile__phone">{localStorage.getItem("number")}</div>
                </div>
                <nav className="profile-nav__list">
                  <ul className="list-unstyled">
                    <NavLink to="/account/addresses" className={ActiveUrl === "/account/addresses" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/addresses")}><li className="item-text"><span className="mx-2"><MdOutlineEditLocation /></span>My Addresses</li></NavLink>
                    <NavLink to="/account/orders" className={ActiveUrl === "/account/orders" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/orders")}><li className="item-text"><span className="mx-2"><AiOutlineReconciliation /></span>My Orders</li></NavLink>
                    {/* <NavLink to="/account/wallet" className={ActiveUrl === "/account/wallet" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/wallet")}><li className="item-text"><span className="mx-2"><BiRupee /></span>My wallet</li></NavLink> */}
                    <NavLink to="/logout" className={ActiveUrl === "/account/addresses" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/logout")}><li className="item-text"><span className="mx-2"><AiOutlineUser /></span>Logout</li></NavLink>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-10 col-md-10 order-overflow" onScroll={handleScroll}>
                <div className="sticky-container">
                  <div className="order-history">
                    <span>Ordered products history</span>
                    {/* <div className="f_18_400">See more <MdArrowDropDown size={24} /></div> */}
                  </div>
                </div>
                {loading ? (
                  <div className="loader-container">
                    <img src={require("../Components/images/loader.gif")} alt="loading" />
                  </div>
                )
                  : (Orders.length > 0 ?
                    (Orders.map((order, index) => {
                      return (
                        <div>
                          <div className="row order" key={index}>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex gap-4 align-items-center">
                                {order.orderstatus === "Cart" && <img src={require("./images/bag.png")} className="image" alt="bag" />}
                                {order.orderstatus === "Placed" && <img src={require("./images/tick.png")} className="image" alt="tick" />}
                                {order.orderstatus !== "Cart" && order.orderstatus !== "Placed" &&
                                  <img src={require("./images/orderimg.png")} alt="" className="image" />
                                }
                                <div>
                                  <h5 className="order_id">Order Id:{order?.id}&nbsp;-&nbsp;₹{order.net_amount}</h5>
                                  <p className="">Placed on {timeString(order.order_date)}</p>
                                </div>
                                <div className={`status_badge ${order.orderstatus === "Cart" || order.orderstatus === "Placed" ? "green" : "grey"}`}>
                                  <div>{order.orderstatus}</div>
                                </div>
                              </div>
                              {(
                                <NavLink to={`${order.id}/${order.addresses?.id}`}>
                                  <button type="button" className="btn btn-outline-success view-button">view details</button>
                                </NavLink>
                              )}
                            </div>
                          </div>
                          <hr />
                        </div>
                      )
                    }))
                    : (<p className="no-orders">You have not ordered anything yet!!!</p>)
                  )
                }
              </div>
            </div>
          </div>
      }
    </>
  )
}
export { MyOrders }