import {React,useEffect} from 'react';
import { Footer, Navbar } from "..";
const Cancellation=()=> {
    let data=JSON.parse(localStorage.getItem("policies"));
    useEffect(() => {
        window.scrollTo(0, 0);
    })
  return (
    <>
    <Navbar />
    <div className="container pt-3">
        {data.cancellation&& <div dangerouslySetInnerHTML={{ __html: data.cancellation }} />}
    </div>
    <Footer />
    </>
   
  )
}
export {Cancellation}
