import React, { useState, useEffect } from "react";
import "./FriendlyUrlComponent.css";
import axiosConfig from "../../Service/axiosConfig";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
import Blog from "../Footer/Blogs";
import BlogDetail from "../Footer/BlogDetail";
import {
  CategoriesPage,
  SubCategoriesPage,
  ProductDetailPage,
  NotFound,
} from "../../Pages";
 
const FriendlyUrlComponent = () => {
  let { subcategoryurl, categoryurl, friendlyurl } = useParams();
  const [redirectURL, setRedirectURL] = useState("");
  let navigate = useNavigate();
  console.log(friendlyurl);
  console.log(categoryurl);
  console.log(subcategoryurl);
  const [loading, setLoading] = useState(false);
  const [is404, setis404] = useState(false);
  const [ProductObject, setProductObject] = useState({
    product_type: "",
    product_data: "",
  });
  const [Data, SetData] = useState([]);
 
  const redirectUrl = async (slug) => {
    try {
      const secondaryResponse = await axiosConfig.get(
        `/catlog/redirectrouter/?old_slug=${slug ? slug : friendlyurl}`
      );
      let new_slug = secondaryResponse.data.results[0]?.seourl.keyword;
      if (new_slug) {
        redirectUrl(new_slug);
      } else if(slug){
        setRedirectURL(slug);
      }else{
        navigate('/not-found', { replace: true });
      }
    } catch (error) {
      // setis404(true);
      console.log("Error redirecting : ", error);
    }
  };
  const GetData = async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `/catlog/seo-url/${redirectURL ? redirectURL : friendlyurl}/`
      );
      console.log(response);
      setProductObject({
        product_type: response.data.product_type,
        product_data: response.data.product_data,
      });
      setLoading(false);
 
      if (
        response.data.product_type === "category" ||
        response.data.product_type === "subcategory"
      ) {
        let item = response.data.product_data;
        if (item==900) {
          // Create or update og:title meta tag
          const ogTitleMeta = document.querySelector(
            'meta[property="og:title"]'
          );
          if (ogTitleMeta) {
            ogTitleMeta.setAttribute("content", item.meta_title);
          } else {
            const newOgTitleMeta = document.createElement("meta");
            newOgTitleMeta.setAttribute("property", "og:title");
            newOgTitleMeta.setAttribute("content", item.meta_title);
            document.head.appendChild(newOgTitleMeta);
          }
 
          // Update or create title tag
          document.title = item.meta_title;
          document.head.appendChild(
            document.createElement("title")
          ).innertitle = item.meta_title;
 
          // Create or update og:description meta tag
          const ogDescriptionMeta = document.querySelector(
            'meta[name="og:description"]'
          );
          if (ogDescriptionMeta) {
            ogDescriptionMeta.setAttribute("content", item.meta_description);
          } else {
            const newOgDescriptionMeta = document.createElement("meta");
            newOgDescriptionMeta.setAttribute("name", "og:description");
            newOgDescriptionMeta.setAttribute("content", item.meta_description);
            document.head.appendChild(newOgDescriptionMeta);
          }
 
          // Create or update description meta tag
          const descriptionMeta = document.querySelector(
            'meta[name="description"]'
          );
          if (descriptionMeta) {
            descriptionMeta.setAttribute("content", item.meta_description);
          } else {
            const newDescriptionMeta = document.createElement("meta");
            newDescriptionMeta.setAttribute("name", "description");
            newDescriptionMeta.setAttribute("content", item.meta_description);
            document.head.appendChild(newDescriptionMeta);
          }
 
          // Create or update keyword meta tag
          const keywordMeta = document.querySelector(
            'meta[property="keyword"]'
          );
          if (keywordMeta) {
            keywordMeta.setAttribute("content", item.meta_keyword);
          } else {
            const newKeywordMeta = document.createElement("meta");
            newKeywordMeta.setAttribute("property", "keyword");
            newKeywordMeta.setAttribute("content", item.meta_keyword);
            document.head.appendChild(newKeywordMeta);
          }
 
          // Create or update og:keyword meta tag
          const ogKeywordMeta = document.querySelector(
            'meta[property="og:keyword"]'
          );
          if (ogKeywordMeta) {
            ogKeywordMeta.setAttribute("content", item.meta_keyword);
          } else {
            const newOgKeywordMeta = document.createElement("meta");
            newOgKeywordMeta.setAttribute("property", "og:keyword");
            newOgKeywordMeta.setAttribute("content", item.meta_keyword);
            document.head.appendChild(newOgKeywordMeta);
          }
 
          // Create or update og:image meta tag
          const ogImageMeta = document.querySelector(
            'meta[property="og:image"]'
          );
          if (ogImageMeta) {
            ogImageMeta.setAttribute("content", item.image?.image);
          } else {
            const newOgImageMeta = document.createElement("meta");
            newOgImageMeta.setAttribute("property", "og:image");
            newOgImageMeta.setAttribute("content", item.image?.image);
            document.head.appendChild(newOgImageMeta);
          }
 
          // Cleanup function to remove dynamically added meta tags when component unmounts
          return () => {
            if (ogTitleMeta) document.head.removeChild(ogTitleMeta);
            if (ogDescriptionMeta) document.head.removeChild(ogDescriptionMeta);
            if (ogKeywordMeta) document.head.removeChild(ogKeywordMeta);
            if (ogImageMeta) document.head.removeChild(ogImageMeta);
            if (descriptionMeta) document.head.removeChild(descriptionMeta);
            if (keywordMeta) document.head.removeChild(keywordMeta);
          };
        }
      }
    } catch (error) {
      console.log(error);
      redirectUrl(null);
      // setis404(true);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetData();
  }, [friendlyurl, redirectURL]);
 
  return (
    <>
      {is404 ? (
        <>
          <NotFound />
        </>
      ) : (
        <div className="categories-container">
          {loading ? (
            <div className="loader-container">
              <img src={require("../images/loader.gif")} alt="Loading..." />
            </div>
          ) : (
            <div className="">
              {ProductObject.product_type == "category" && (
                <>
                    <CategoriesPage
                      category_id={ProductObject.product_data.id}
                      category_slug={friendlyurl}
                    />
                    <Helmet>
                      <title>{ProductObject.product_data.meta_title}</title>
                      <meta property="og:title" content={ProductObject.product_data.meta_title} />
                      <meta name="description" content={ProductObject.product_data.meta_description} />
                      <meta name="og:description" content={ProductObject.product_data.meta_description} />
                      <meta property="keywords" content={ProductObject.product_data.meta_keyword} />
                      <meta property="og:keywords" content={ProductObject.product_data.meta_keyword} />
                      {ProductObject.product_data.image && 
                          <meta name="og:image" property="og:image" content={ProductObject.product_data.image.image} />
                      }
                    </Helmet>
                </>
 
              )}
               {ProductObject.product_type == "blogcategory" &&
                        <Blog category={ProductObject.product_data.id}  />
                    }
                     {ProductObject.product_type == "blog" &&
                        <BlogDetail item={ProductObject.product_data}  />
                    }
              {ProductObject.product_type == "subcategory" && (
                <>
                <SubCategoriesPage
                  subcategory_id={ProductObject.product_data.id}
                  category_id={
                    ProductObject.product_data &&
                    ProductObject.product_data.category.id
                  }
                  friendlyurl={friendlyurl}
                />
                <Helmet>
                      <title>{ProductObject.product_data.meta_title}</title>
                      <meta property="og:title" content={ProductObject.product_data.meta_title} />
                      <meta name="description" content={ProductObject.product_data.meta_description} />
                      <meta name="og:description" content={ProductObject.product_data.meta_description} />
                      <meta property="keywords" content={ProductObject.product_data.meta_keyword} />
                      <meta property="og:keywords" content={ProductObject.product_data.meta_keyword} /> 
                      {ProductObject.product_data.image && 
                          <meta name="og:image" property="og:image" content={ProductObject.product_data.image.image} />
                      }
                    </Helmet>
                </>
 
              )}
              {ProductObject.product_type == "product" && (
                <>
                <ProductDetailPage
                  product_data={ProductObject.product_data}
                  friendlyurl={friendlyurl}
                  categoryurl={categoryurl}
                  subcategoryurl={subcategoryurl}
                />
                <Helmet>
                      <title>{ProductObject.product_data.meta_title}</title>
                      <meta property="og:title" content={ProductObject.product_data.meta_title} />
                      <meta name="description" content={ProductObject.product_data.meta_description} />
                      <meta name="og:description" content={ProductObject.product_data.meta_description} />
                      <meta property="keywords" content={ProductObject.product_data.meta_keyword} />
                      <meta property="og:keywords" content={ProductObject.product_data.meta_keyword} />
                      {ProductObject.product_data.image && 
                          <meta name="og:image" property="og:image" content={ProductObject.product_data.image.image} />
                      }
                    </Helmet>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
export { FriendlyUrlComponent };