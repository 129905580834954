import React, { useState, useEffect } from "react";
import { Footer, Navbar } from "..";
import axiosConfig from "../../Service/axiosConfig";

const PrivacyPage = () => {
    const [formData, setFormData] = useState({})
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    const GetPolicies = async () => {
        try {
            const responce = await axiosConfig.get(`/catlog/policies/`);
            console.log(responce.data.results)
            setFormData(responce.data.results[0])
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetPolicies()
    }, [])

    return (
        <>

            <Navbar />
            <div className="container pt-3">
                {formData.privacy_policy && <div dangerouslySetInnerHTML={{ __html: formData.privacy_policy }} />}
            </div>
            {/* <div className="container pt-3">
                <h4>PRIVACY POLICY</h4>
                <p>The user’s privacy policy is Opoto’s commitment to protect the privacy of the user!</p>
                <p><b>Opoto.in</b>, its website, mobile applications, sub-domains, its services are operated and owned by M/s MAS Associates, Hyderabad, Telangana here referred to as <b>“Opoto or Website or We or Us”</b>.</p>
                <b>Users</b>
                <p>
                    All users who visit, access, use the website, mobile applications, affiliates, and all sub-domains in any forms hereunder referred to as “Users or You or Your”.
                    This Privacy Policy is published in compliance with the (Indian) Information Technology Act, 2000 and the rules, regulations, guidelines, and clarifications framed hereunder, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (SPI Rules).
                    This Privacy Policy explains the type and quantity of information we collect and how we collect, use, share, disclose and protect Sensitive Personal information about the Users.
                    By using / visiting / accessing Opoto Services or giving us your information you will be deemed to have read carefully, understood, and agreed to this Privacy Policy.
                </p>
                <b>Collection of Personal Information</b>
                <p>
                    To serve Opoto users best, Opoto may need to know who is the user so that Opoto can understand the end user's needs. When you access our services or interact with us via emails, telephone calls, or other correspondence, Opoto may ask you to voluntarily provide us certain information that personally identifies you or could be used to personally identify you. You hereby consent to the collection of such information by the company. Without prejudice to the generality of the above, information collected by us from you may include (but is not limited to) the following:
                    Personal and Contact Information (Full Name, Email ID, Phone Number, User login ID, Password);
                    Demographic Information (Gender, Age/Date of birth, Address, IP Address, Geo-Location, Search Criteria, Browser, Cookies Information, Type of Device, and your pin code);
                    Purchase History and Wishlists used by you through Opoto;
                    Chat, Email or Call Data Records;
                    Any information received by body corporate under lawful contract or otherwise;
                    Financial Information such as bank account details, Credit Card, Debit Card, Netbanking, UPI, Any Payment App, Payment Wallet, or any other payment instrument which you use to make payments for any Opoto services.
                    The other information that you voluntarily choose to provide us (such as information shared by you with us through any form of communication).
                    The information collected from you by the Opoto may constitute ‘personal information or ‘sensitive personal data or information under the SPI Rules.
                    “Personal Information” is defined under the SPI Rules to mean any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available to a body corporate, is capable of identifying such person.
                    However, Company will be free to use, collect and disclose information that is freely available about you in the public domain without your consent.
                </p>
                <b>Use Of Demographic / Profile Data / Your Information</b>
                <p>
                    We use the collected personal information through the website or any form of sources to extend our best services, offers, and promotions to you. We may provide the Personal Information to our partners and/or affiliates who work on behalf of or with Opoto. In such circumstances, you consent to us disclosing your Personal Information to such parties and contractors, solely for the intended purposes only.
                    With your consent, we will have access to your SMS, contacts in your directory, location, and device information to enhance your experience on the platform and provide you access to the services being offered by us, our affiliates, or partners. Access, storage, and use of this data will be in consonance with applicable laws. You should understand that your access to these services may be affected in the event when the consent is withdrawn.
                    In our efforts to continually improve service offerings, we and our affiliates collect and analyze demographic and profile data about our users' activity on our Website.
                </p>
                <b>Cookies</b>
                <p>
                    A "cookie" is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. We place both permanent and temporary cookies in your computer's hard drive. The cookies do not contain any of your personally identifiable information.
                </p>
                <b>Privacy And Security Precautions</b>
                <p>
                    We have taken strict security measures in place to protect the loss, misuse, and alteration of the information under our control. We adhere to strict data security guidelines, protecting it against unauthorized access.
                </p>
                <b>Choice/OPT-OUT</b>
                <p>
                    We provide all users with the opportunity to opt-out of receiving non-essential (Sales, Promotional, Branding, Marketing Related) communications from us on behalf of our partners, and from us in general, after setting up an account.
                    If you want to remove your contact information from all Opoto lists and newsletters, you can visit unsubscribe sections.
                </p>
                <b>Your Consent</b>
                <p>
                    By using Opoto and/or by providing your information, you consent to the collection and use of the information you disclose to Opoto in accordance with this Privacy Policy, including but not limited to your consent for sharing your information as per this privacy policy. If you disclose any personal information relating to other people (family members, associates, employees, partners, etc) to us, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.
                </p>
                <b>Change in Privacy Policy</b>
                <p>
                    If any changes are made to our privacy policy periodically, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.
                </p>
                <b>Grievance Officer</b>
                <p>
                    In case of any queries or assistance regarding the Privacy Policy you can contact our grievance officer, provided below the name and contact details of the Grievance Officer:

                    Santosh Pothamshetty<br />
                    Opoto.in (A unit of M/s MAS Associates)<br />
                    # 202, Suraj Mansion, Lane Opposite to Lal Bunglaw,<br />
                    Leelanagar, Ameerpet,<br />
                    Hyderabad, Telangana Pin -500016.<br />
                    Email: support@Opoto.in<br />
                    Phone: +91 9618114770.<br />
                    Time: Mon-Sat (9:00 - 18:00)
                </p>
            </div> */}
            <Footer />
        </>
    )

}
export { PrivacyPage }