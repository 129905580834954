import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const HomeMeta = ({ siteData }) => {
    useEffect(() => {
        if (siteData.googleanalytics_id) {
            // Inject the external Google Analytics script
            const scriptTag = document.createElement('script');
            scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=G-${siteData.googleanalytics_id}`;
            scriptTag.async = true;
            document.head.appendChild(scriptTag);

            // Inject the inline script after the external one has loaded
            scriptTag.onload = () => {
                const inlineScript = document.createElement('script');
                inlineScript.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-${siteData.googleanalytics_id}');
                `;
                document.head.appendChild(inlineScript);
            };

            // Disable GA initially if needed
            window[`ga-disable-G-${siteData.googleanalytics_id}`] = true;
        }
    }, [siteData.googleanalytics_id]);

    return (
        <>
            <Helmet>
                {/* Page Title */}
                <title>{siteData.title}</title>

                {/* Meta Tags for SEO */}
                <meta name="description" content={siteData.meta_description} />
                <meta name="keywords" content={siteData.keywords} />

                {/* Open Graph Meta Tags for Social Sharing */}
                <meta property="og:title" content={siteData.title} />
                <meta property="og:description" content={siteData.meta_description} />
                <meta property="og:keywords" content={siteData.keywords} />

                {/* Favicon */}
                {siteData.favicon && (
                    <link rel="icon" href={siteData.favicon.image} type="image/x-icon" />
                )}

                {/* Open Graph Image (Social Preview Image) */}
                {siteData.logo && (
                    <meta property="og:image" content={siteData.logo.image} />
                )}
            </Helmet>
        </>
    );
};

export { HomeMeta };
