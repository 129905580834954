import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormParser } from '../FormParser/FormParser';
import Select from 'react-select';
import axiosConfig from "../../Service/axiosConfig"
function AddAddress({ addressModal, closeAddressModal, MastersModal, formSubmit, setData, Error, setPopupType, GetData, Data }) {
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [ADDRESSType, setAddressType] = useState("Home");
    const [City, setcity] = useState("");
    const [error, setError] = useState(false);
    const [state, setState] = useState("");
    let storedPincode = localStorage.getItem("pincode") ? (localStorage.getItem("pincode")) : "";
    const [formData, setFormData] = useState({
        name: "",
        pincode: storedPincode,
        mobileno: "",
        address: "",
        landmark: "",
        adress_type: "Home"
    });
    // Handle change event
    const handleChange = (selectedOption) => {
        setFormData({ ...formData, ["landmark"]: selectedOption.label })
    };
    const handleInput = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    console.log(formData)
    const formConventionalSubmit = async (e) => {
        e.preventDefault();
        try {
            if (formData.id) {
                const res = await axiosConfig.patch(`/accounts/address/${formData.id}/`, { ...formData, "user": localStorage.getItem("userId") });
                console.log(res, "address added")
                setPopupType("success")
                handleCloseModal();
                setcity("")
                setState("")
                GetData && GetData();
            }
            else {
                const res = await axiosConfig.post(`/accounts/address/`, { ...formData, "user": localStorage.getItem("userId") });
                console.log(res, "address added")
                setPopupType("success")
                handleCloseModal();
                setcity("")
                setState("")
                GetData && GetData();
            }
        }
        catch (error) {
            console.error(error);
            setPopupType("error")
        }
    }
    const localityFilter = async () => {
        if (formData.pincode.length === 6) {
            try {
                const res = await axiosConfig.get(`/masters/areas/?city=&is_suspended=false&pincode=${formData.pincode}`);
                if (res.data.results.length > 0) {
                    setOptions(res.data.results)
                    setcity(res.data.results[0]?.city?.city_name)
                    setState(res.data.results[0]?.city?.state?.state_name)
                    setError(false)
                }
                else {
                    setError(true)
                    setcity("")
                    setState("")
                    setOptions([])
                }
            }
            catch (error) {
                console.error(error);
                setError(true)
            }
        }
        else {
            return null
        }
    }
    const handleCloseModal = () => {
        closeAddressModal()
        setError("")
        setState("")
        setcity("")
        setData&&setData()
        const storedPincode = localStorage.getItem("pincode") ? (localStorage.getItem("pincode")) : "";
        setFormData({
            name: "",
            pincode: storedPincode,
            mobileno: "",
            address: "",
            landmark: "",
            adress_type: "Home"
        })
    }
    useEffect(() => {
        if (Data) {
            console.log("Data")
            setFormData(Data);
        } else {
            const storedPincode = localStorage.getItem("pincode") || "";
            const number = localStorage.getItem("number") || "";
            setFormData(prevFormData => ({
                ...prevFormData,
                pincode: storedPincode,
                mobileno:number
            }));
        }
    }, [Data, addressModal]);

    useEffect(() => {
        localityFilter();
    }, [formData.pincode,addressModal]);

    return (
        <Modal
            size="lg"
            show={addressModal}
            onHide={handleCloseModal}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {Data?.id ? "Edit Address" : "Add Address"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    JSON.parse(localStorage.getItem("site_data"))?.ecommerce_type === "Conventional" ? <form onSubmit={formConventionalSubmit}>
                        <div className='addressFormUI-base-formHeader'>CONTACT DETAILS</div>
                        <div className='form-group'>
                            <label>Name*:</label>
                            <input className='form-control' placeholder='Name' name='name' value={formData.name} onChange={handleInput} required />
                        </div>
                        <div className='form-group'>
                            <label>Mobile No*:</label>
                            <input className='form-control' placeholder='Mobile No' name='mobileno' value={formData.mobileno} onChange={handleInput} required />
                        </div>
                        <div className='addressFormUI-base-formHeader'>ADDRESS</div>
                        <div className='form-group'>
                            <label>Pincode*:</label>
                            <input className='form-control' type='number' placeholder='Pincode' maxLength="6" name='pincode' value={formData.pincode} onChange={handleInput} required />
                            {error &&
                                <div className="Loginerror">Service not available for this selected pincode</div>
                            }
                        </div>
                        <div className='form-group'>
                            <label>Address(House No,Building,Street,Area)*</label>
                            <textarea className='form-control' placeholder='Address(House No,Building,Street,Area)' name='address' value={formData.address} onChange={handleInput} required />
                        </div>
                        <div className='form-group'>
                            <label>Locality/Town*:</label>
                            <Select
                                id="my-select"
                                onChange={(selectedOption) => handleChange(selectedOption, "landmark")}
                                options={options.map(res => ({ value: res.id, label: res.name }))}
                                value={options.map(res => ({ "value": res.id, "label": res.name })).find(res => res.label === formData.landmark)}
                                placeholder="Locality/Town"
                                name='landmark'
                                required
                            />
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <input className='form-control' value={City} disabled />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <input className='form-control' value={state} disabled />
                                </div>
                            </div>
                        </div>
                        <div className='addressFormUI-base-formHeader'>SAVE ADDRESS AS</div>
                        <div className='d-flex my-4'>
                            <div class={`addressFormUI-base-addressTypeIcon ${formData.adress_type === "Home" ? "addressFormUI-base-selectedAddressType" : ""}`} id="addressType-home" data-value="Home" onClick={() => setFormData({ ...formData, "adress_type": "Home" })}>Home</div>
                            <div class={`addressFormUI-base-addressTypeIcon ${formData.adress_type === "Work" ? "addressFormUI-base-selectedAddressType" : ""}`} id="addressType-home" data-value="Work" onClick={() => setFormData({ ...formData, "adress_type": "Work" })}>Work</div>
                        </div>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-primary btn-block">Save Address</button>
                        </Modal.Footer>
                    </form> :
                        <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} formData={Data} buttonTitle={"Save Address"} />
                }
            </Modal.Body>
        </Modal>
    );
}

export default AddAddress;
