import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addProduct,
  selectCartProducts,
  selectCartTotal,
  removeProduct,
  fetchProducts,
} from "../../redux/Slices/cartSlice";
import { MdAdd, MdRemove, MdOutlineShoppingBag } from "react-icons/md";
import { CiDiscount1 } from "react-icons/ci";
import { TfiTimer } from "react-icons/tfi";
import axiosConfig from "../../Service/axiosConfig";
import { Products } from "../Products";
import { BsFileTextFill } from "react-icons/bs";
import { RiMotorbikeFill } from "react-icons/ri";
import { TbArrowBigUpLines } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import Modal from "react-bootstrap/Modal";
import { TbCircleArrowUpRightFilled } from "react-icons/tb";
const ViewCart = ({
  handleClose,
  setOrders,
  Order,
  setTotalAmount,
  orderAddress,
  productAmount,
  travelTime,
  setMiniPopUp,
}) => {
  const products = useSelector(selectCartProducts);
  const total = useSelector(selectCartTotal);
  const dispatch = useDispatch();
  let Navigate = useNavigate();
  let ProductData = [];
  const [modalShow, setModalShow] = React.useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [waiverData, setWaiverData] = useState({});
  const [deliveryData, setDeliveryData] = useState({});
  const [packingData, setPackingData] = useState([]);

  const handleRemove = (productId, quantity) => {
    dispatch(removeProduct({ productId, quantity }));
  };

  const handleShopping = () => {
    Navigate("/");
    handleClose();
  };
  const handleShowModal = (title) => {
    setMiniPopUp(true);
    setModalTitle(title);
    setModalShow(true);
  };

  const addToCart = (product) => {
    dispatch(addProduct({ product: product, quantity: 1 }));
    console.log("added");
  };

  const HandleMiniPopUpClose = () => {
    setMiniPopUp(false);
    setModalShow(false);
  };
  useEffect(() => {
    console.log(productAmount);
    products.forEach((res) => {
      ProductData.push({
        id: res.id,
        min_qty: res.quantity,
      });
    });
    setOrders && setOrders({ ...Order, products: ProductData });
    // const serializedState = JSON.stringify(products);
    // products.length > 0 && localStorage.setItem('state', serializedState)
  }, [products]);
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);
  const GetChargesData = async () => {
    try {
      const waiver_response = await axiosConfig.get("/masters/waier-charges/");
      const delivery_response = await axiosConfig.get(
        "/masters/delivery-charges-configuration/"
      );
      const packing_response = await axiosConfig.get(
        "/masters/packing-charges-configuration/"
      );
      console.log(packing_response, "packing_response");
      if (waiver_response.data.results.length > 0) {
        setWaiverData(waiver_response.data.results[0]);
      }
      if (delivery_response.data.results.length > 0) {
        setDeliveryData(delivery_response.data.results[0]);
      }
      if (packing_response.data.results.length > 0) {
        let schedule_packings = packing_response.data.results.filter(
          (p) => p.delivery_type === "Schedule"
        );
        setPackingData(schedule_packings);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetChargesData();
  }, []);
  const MyCenteredModal = (props) => {
    return (
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={HandleMiniPopUpClose}
        show={modalShow}
      >
        <Modal.Body className="modal-body-viewCart">
          {modalTitle === "Packing Charges" ? (
            <>
              <h2>Packing Charges</h2>
              {packingData.length > 0 &&
                packingData.map((p) => {
                  return (
                    <p>
                      {Math.round(p.from_kg)}Kg to {Math.round(p.to_kg)}Kg
                      charges ₹{parseFloat(p.charges).toFixed(2)}
                    </p>
                  );
                })}
              {waiverData && (
                <p>
                  ₹0 for order value above{" "}
                  {waiverData.schedule_packing_waier_order_value_above}
                </p>
              )}
            </>
          ) : (
            <>
              {console.log(
                deliveryData.schedule_delivery_charges,
                "deliveryData"
              )}
              <h2>Delivery Charges</h2>
              {deliveryData &&
                (localStorage.getItem("delivery_type") &&
                localStorage.getItem("delivery_type") === "Schedule" ? (
                  <p>
                    Schedule delivery charges ₹
                    {deliveryData.schedule_delivery_charges}
                  </p>
                ) : (
                  <p>
                    Express delivery charges ₹
                    {deliveryData.express_delivery_charges}
                  </p>
                ))}
              {Order.spike_percentage > 0 && (
                <p>
                  {Order.spike_percentage}% spike {Order.spike_comment}
                </p>
              )}
              {waiverData &&
                (localStorage.getItem("delivery_type") === "Schedule" ? (
                  <p>
                    ₹0 for order value above{" "}
                    {waiverData.schedule_delivery_waier_order_value_above}
                  </p>
                ) : (
                  <p>
                    ₹0 for order value above{" "}
                    {waiverData.express_delivery_waier_order_value_above}
                  </p>
                ))}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer-viewCart">
          <span className="modal-footer-text" onClick={HandleMiniPopUpClose}>
            <b>Sounds good</b>
          </span>
        </Modal.Footer>
      </Modal>
    );
  };
  console.log(Order, "order");
  return (
    <div>
      {products.length === 0 ? (
        <div className="Empty-cart">
          <div>
            <img
              className="empty-img"
              src={require("../Navbar/images/emp_empty_cart.png")}
              alt="emp"
            />
          </div>
          <p className="p-delivery-time">
            You don't have any items in your cart
          </p>
          <p className="message-cart">
            Your favourite items are just a click away
          </p>
          <button
            type="button"
            className="btn--link"
            onClick={() => handleShopping()}
          >
            Start Shopping
          </button>
          {/* <p className="p-qty">{cart.products.length} items</p> */}
        </div>
      ) : (
        <>
          <div className="bill-details py-2">
            <div className="cart-timer">
              <div className="cart-timer-icon">
                <TfiTimer size={24} />
              </div>
              <div>
                {localStorage.getItem("site_data") &&
                  JSON.parse(localStorage.getItem("site_data"))
                    .ecommerce_type !== "Conventional" && (
                    <p className="p-delivery-time">
                      {travelTime
                        ? `Delivery in  ${travelTime ? travelTime : 10} minutes`
                        : "Delivery to"}
                    </p>
                  )}
                <p className="p-qty">Shipment of {products.length} items</p>
              </div>
            </div>
            {products.map((product) => (
              <div className="productItem" key={product.id}>
                <div className="row">
                  <div className="col-2">
                    <img
                      src={product.image ? product.image.image : product.imagef}
                      className="cart-display-img"
                    />
                  </div>
                  <div className="col-6">
                    <p>{product.name}</p>
                    {product.weight_in_kg && product.weight_in_kg > 0 ? (
                      <>
                        <p>{product.weight_in_kg}</p>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="d-flex">
                      {product.offer_price && product.offer_price > 0 ? (
                        <>
                          <p className="cart-item-price">
                            ₹{product.offer_price}
                          </p>
                          <p
                            className={
                              product.offer_price
                                ? "produc-price ms-2"
                                : "cart-item-price"
                            }
                          >
                            ₹{product.price}
                          </p>
                        </>
                      ) : (
                        <p className="cart-item-price">₹{product.price}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="p-btn">
                      <div className="btn btn-cart btn-cart-added">
                        <button
                          className="btn-minus"
                          onClick={() => handleRemove(product.id, 1)}
                        >
                          <div className="custom-minus"></div>
                        </button>
                        <div className="qty">{product.quantity}</div>
                        <button
                          className="btn-minus"
                          disabled={product.max_qty === product.quantity}
                          onClick={() => addToCart(product)}
                        >
                          <div className="custom-plus"></div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <td>{product.name}</td>
                  <td>${product.price.toFixed(2)}</td>
                  <td>{product.quantity}</td>
                  <td>${(product.price * product.quantity).toFixed(2)}</td>
                  <td>
                    <button onClick={() => handleRemove(product.id, product.quantity)}>Remove</button>
                  </td> */}
              </div>
            ))}
          </div>
          {console.log(products, "products")}
          {products.length > 0 && (
            <div className="bill-details py-2">
              {console.log(products[0], "view cart.............")}
              <Products
                id={products[0].id}
                products_for="Before you checkout"
                filter_id={products[0].subcategory}
                insideCart={true}
              />
            </div>
          )}
          <div className="bill-details f_12_500 f_roboto py-2">
            <div className="bill-head">Bill details</div>
            <div className="rowBetweenCenter mb-2">
              <div className="rowCenter">
                <BsFileTextFill size={14} style={{ marginRight: "6px" }} /> Item
                total
                {productAmount.discount + productAmount.PackingCharges > 0 && (
                  <div className="saved">
                    saved ₹{parseFloat(productAmount.discount).toFixed(2)}
                  </div>
                )}
              </div>
              {productAmount.discount > 0 ? (
                <div>
                  <strike>₹{parseFloat(productAmount.mrp).toFixed(2)}</strike>
                  <span>
                    {" "}
                    ₹
                    {parseFloat(
                      productAmount.mrp - productAmount.discount
                    ).toFixed(2)}
                  </span>
                </div>
              ) : (
                <div>₹{parseFloat(productAmount.mrp).toFixed(2)}</div>
              )}
            </div>
            {console.log(orderAddress, "inside view cart")}
            {/* {orderAddress && */}
            <>
              {
                <div className="rowBetweenCenter">
                  <div className="rowCenter">
                    <RiMotorbikeFill size={14} style={{ marginRight: "6px" }} />{" "}
                    Delivery charges
                    <HiOutlineInformationCircle
                      className="icon-info"
                      onClick={() => {
                        handleShowModal("Delivery Charges");
                      }}
                      size={40}
                    />
                    <MyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                  <div>
                    {productAmount.delivery_waived ? (
                      <div>
                        <strike>
                          ₹
                          {parseFloat(productAmount.delivery_waived).toFixed(2)}
                        </strike>{" "}
                        <blue> waiver</blue>
                      </div>
                    ) : (
                      <div>
                        ₹{parseFloat(productAmount.deliveryCharges).toFixed(2)}
                      </div>
                    )}
                  </div>
                </div>
              }
              <div className="rowBetweenCenter">
                <div className="rowCenter">
                  <MdOutlineShoppingBag
                    size={14}
                    style={{ marginRight: "6px" }}
                  />{" "}
                  Packing charges
                  <HiOutlineInformationCircle
                    className="icon-info"
                    onClick={() => {
                      handleShowModal("Packing Charges");
                    }}
                    size={40}
                  />
                </div>
                <div>
                  {productAmount.packing_waived ? (
                    <div>
                      <strike>
                        ₹{parseFloat(productAmount.packing_waived).toFixed(2)}
                      </strike>{" "}
                      <blue> waiver</blue>
                    </div>
                  ) : (
                    <div>
                      ₹{parseFloat(productAmount.PackingCharges).toFixed(2)}
                    </div>
                  )}
                </div>
              </div>
            </>
            {/* } */}
            <div className="grand-total">
              <div className="f_13_700">Grand total</div>
              <div className="grand-total">
                {/* ₹{orderAddress ? parseFloat(productAmount.totalAmount).toFixed(2) : parseFloat(productAmount.mrp).toFixed(2)} */}
                ₹{productAmount.totalAmount.toFixed(2)}
                {console.log(productAmount, "view cart.............")}
              </div>
            </div>
            {/* <div className="cuppons-aply">
              <CiDiscount1 size={20} className="me-2" />
              Coupons are only applicable on the Opoto app
            </div> */}
          </div>
          <div className="bill-details">
            <div className="CancellationPolicy">Cancellation Policy</div>
            <div className="CancellationPolicy-content">
              Orders cannot be cancelled once packed for delivery. In case of
              unexpected delays, a refund will be provided, if applicable.
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export { ViewCart };
