import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig"
// import { NavLink } from "react-router-dom";
import Product from "../Components/Products/Product";
import { Navbar } from "../Components";
import "../Pages/wishlist.css";

const Wishlistpage = () => {

    const [loading, setLoading] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [Data, SetData] = useState([])
    let user = localStorage.getItem("userId");
    let page = 1;
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/catlog/wishlists/?page_size=50&user=${user}&store=${localStorage.getItem("storeId")}&is_suspended=false`)
            console.log(responce.data.results)
            SetData(responce.data.results)
            setLoading(false)
            setIsDeleted(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        GetData();
    }, [isDeleted])

    return (
        <>
            <Navbar />
            <div className="wishlist-container container">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('../Components/images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <>
                        {Data.length >= 0 &&
                            <div className="catagery-items-grid">
                                {
                                    Data.map((itemObj, index) => {
                                        let i = { ...itemObj.product, "slug": itemObj.slug, "available": itemObj.available }
                                        return (
                                            <Product item={i} index={index} user={itemObj.user.id} id={itemObj.id} setIsDeleted={setIsDeleted} />
                                        )
                                    })
                                }
                            </div>
                        }
                    </>
                )}
            </div>
        </>
    )
}
export { Wishlistpage };