import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import { Login } from "../Components/Login/Login";
import { NavLink, } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import { useSelector, useDispatch } from "react-redux";
import { addWishlist, removeWishlist } from "../redux/Slices/cartSlice";
import { LoginModal } from "./LoginModal/LoginModal";

const WishlistIcon = (props) => {
    let { productId } = props;
    let { setIsDeleted } = props;
    let { Setlogin, LoginAccount } = props
    // console.log(props)
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state);
    let userId = localStorage.getItem("userId");
    const [WishId, setWishId] = useState(false);
    const [loginShow, setLoginShow] = useState(false);
    const handleLoginShow = () => setLoginShow(true);
    const addToWishlist = (id) => {
        if (localStorage.getItem('token')) {
            axiosConfig.post(`/catlog/wishlists/`, { user: userId, product: id })
                .then(response => {

                    dispatch(addWishlist({ productId: id, wish_id: response.data.id }));
                    console.log("Product added to wishlist:", response.data);
                    console.log("wish id:", WishId, cart.wishlists, productId)
                })
                .catch(error => {
                    console.error("Error adding product to wishlist:", error);
                })
        } else {
            setLoginShow(true);
        }
    };
    const removeFromWishlist = () => {
        let wishlists = JSON.parse(localStorage.getItem('wishlists'))
        let wishlistItem = wishlists.find((p) => p.id === productId);
        console.log(wishlistItem.wish_id)
        if (wishlistItem.wish_id) {
            axiosConfig.delete(`/catlog/wishlists/${wishlistItem.wish_id}/`)
                .then(response => {
                    dispatch(removeWishlist({ 'productId': productId }));
                    setIsDeleted(true)
                    console.log("remove product from wishlist", response)
                })
                .catch(error => {
                    console.error("Error removing product from wishlist:", error);
                })
        }

    };
    return (
        <div className="icon-wishlist">
            
            {cart.wishlists.some((p) => p.id === productId) ? (
                <AiFillHeart onClick={() => removeFromWishlist()} />
            ) : (
                <AiOutlineHeart onClick={localStorage.getItem("userId") ? () => addToWishlist(productId) : handleLoginShow} />
            )}
            {
                loginShow &&
                <LoginModal setLoginType={setLoginShow} LoginType={loginShow} setLoggedIn={Setlogin} LoggedIn={LoginAccount} />
            }
        </div>
    )
}
export default WishlistIcon