import React, { useState, useRef, useEffect } from "react";
import "./Products.css";
import axiosConfig from "../../Service/axiosConfig";
import { NavLink, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Product from "./Product";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

function SampleNextArrow(props) {
  const { className, style, onClick, insideCart } = props;
  {
    console.log("claass:", className);
  }
  return (
    <div className="next-arrow">
      <div
        className={className}
        style={{
          ...style, display: "none", right: insideCart ? "-10px" : "-42px"
        }}
        onClick={onClick}
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, insideCart } = props;
  console.log("calss:", className, props);
  return (
    <div className="previous-arrow">
      <div
        className={className}
        style={{
          ...style, display: "none", left: "-10px",
        }}
        onClick={onClick}
      />
    </div>
  );
}

const Products = (props) => {
  console.log("fetch product", props);
  const { insideCart } = props;
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Data, SetData] = useState([]);
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const GetData = async (props) => {
    setLoading(true);
    try {
      console.log(props.filter_id);
      const responce = await axiosConfig.get(
        `/catlog/products/?subcategory=${props.filter_id}&?page_size=7&store=${localStorage.getItem("storeId")}&is_suspended=false`
      );
      console.log(responce, "res...")
      SetData(responce.data.results);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const GetSimilarProducts = async (id) => {
    try {
      const orderId = localStorage.getItem('orderId')
      const response = await axiosConfig.get(`/catlog/get_similar_products/?product_id=${id}&order_id=${orderId ? orderId : ''}`)
      let similar_products = response.data.map(item => {
        return item.product
      })
      console.log(response.data, "similar...")
      SetData(similar_products)
    } catch (error) {
      console.log("Error getting similar products : ", error)
    }
  }
  useEffect(() => {
    if (props.id) {
      GetSimilarProducts(props.id)
    } else {
      GetData(props);
    }
  }, [props.id]);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: insideCart ? 2 : 6,
    slidesToScroll: insideCart ? 2 : 6,
    nextArrow: <SampleNextArrow insideCart={insideCart} />,
    prevArrow: <SamplePrevArrow insideCart={insideCart} />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: insideCart ? 2 : 5,
          slidesToScroll: insideCart ? 2 : 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: insideCart ? 2 : 4,
          slidesToScroll: insideCart ? 2 : 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: insideCart ? 2 : 3,
          slidesToScroll: insideCart ? 2 : 3,
        },
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="products-container">
        {loading ? (
          <div className="loader-container">
            <img src={require("../images/loader.gif")} alt="Loading..." />
          </div>
        ) : (
          <>
            {Data.length > 0 && (
              <>
                <div className="container">
                  {/* <h2>{props.products_for}</h2> */}
                  <div className="d-flex justify-content-between">
                    <h2>{props.products_for}</h2>
                    <div className="d-flex align-items-center">
                      {props.product_slug && (
                        <NavLink
                          to={`/${props.product_slug}`}
                          className="view-all"
                        >
                          View More
                        </NavLink>
                      )}
                    </div>
                  </div>
                  <div className="slider-container">
                    {Data.length >= 6 ? (
                      <Slider
                        ref={slider => {
                          sliderRef = slider;
                        }}
                        {...settings}
                      >
                        {Data.map((itemObj, index) => {
                          return <Product item={itemObj} index={index} LoginAccount={props.LoginAccount} Setlogin={props.Setlogin} />;
                        })}
                      </Slider>
                    ) : (
                      Data.length > 2 && insideCart ? (
                        <Slider
                          ref={slider => {
                            sliderRef = slider;
                          }}
                          {...settings}
                        >
                          {Data.map((itemObj, index) => {
                            return <Product item={itemObj} index={index} LoginAccount={props.LoginAccount} Setlogin={props.Setlogin} />;
                          })}
                        </Slider>
                      ) 
                      :
                        <div className={`grid_${Data.length >= 6 ? '6' : '5'}`}>
                          {Data.map((itemObj, index) => {
                            return (
                              <div
                                // className="col-lg-2 col-md-3 col-sm-6"
                                style={{ marginRight: '3%' }}
                                key={index}
                              >
                                <Product item={itemObj} index={index} />
                              </div>
                            );
                          })}
                        </div>
                    )}
                    <div className="left"><MdChevronLeft size={30} onClick={previous} /></div>
                    <div className="right"><MdChevronRight size={30} onClick={next} /></div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div >
    </>
  );
};
export { Products };
